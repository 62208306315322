export function sendEventForSingleSearchResult(searchString, contentIds) {
    const metaPixelIsAvailable = typeof fbq !== 'undefined';
    if (metaPixelIsAvailable) {
        fbq('track', 'Search', {
            search_string: searchString,
            content_ids: contentIds,
        });
    }
}

export function sendEventForTeaserSearchResult(contents) {
    const metaPixelIsAvailable = typeof fbq !== 'undefined';
    if (metaPixelIsAvailable) {
        // uncomment when OM approved data structure
        // fbq("trackCustom", "SearchWithTeasers", {
        //     contents: contents,
        // });
    }
}
