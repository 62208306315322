import React from 'react';
/* eslint-disable max-len */
const IconKinder = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19.993486 32.904736">
        <g
            data-name="Ebene 2"
            transform="translate(-7.8890112e-4)">
            <g
                data-name="Ebene 1">
                <circle
                    cx="10"
                    cy="5.1100001"
                    r="5.1100001" />
                <rect
                    x="2.79"
                    y="7.2199998"
                    width="3.25"
                    height="11.16"
                    rx="1.63"
                    transform="rotate(-45,4.4178236,12.790078)" />
                <rect
                    x="13.95"
                    y="7.2199998"
                    width="3.25"
                    height="11.16"
                    rx="1.63"
                    transform="rotate(45,15.580666,12.793723)" />
                <rect
                    x="3.49"
                    y="21.190001"
                    width="3.72"
                    height="12.28"
                    rx="1.86"
                    transform="rotate(-150,5.3517563,27.333027)" />
                <rect
                    x="12.79"
                    y="21.209999"
                    width="3.72"
                    height="11.97"
                    rx="1.86"
                    transform="rotate(150,14.646483,27.19858)" />
                <path
                    d="M 15.49,24.94 H 11.22 A 1.46,1.46 0 0 0 10.09,24.25 1.34,1.34 0 0 0 8.87,25 H 4.54 a 21.78,21.78 0 0 0 0.92,-5.76 21.78,21.78 0 0 0 -1,-7.25 l 1.2,-0.32 a 8.37,8.37 0 0 0 4.59,1.25 8.31,8.31 0 0 0 4.19,-1.27 l 1.18,0.34 a 24.25,24.25 0 0 0 -0.92,7.43 24.7,24.7 0 0 0 0.79,5.52 z" />
            </g>
        </g>
    </svg>
);

export default IconKinder;
