import React, { Component } from 'react';
import * as helper from '../../../Common/html-helper';
import FragmentPlaceholder from '../../../Common/fragment-placeholder/fragment-placeholder';
import { IL10N } from '../../../Common/l10n-keys';
import styles from './searchbox.scss';
import classNames from 'classnames';

export default class BottomSearchbox extends Component<{style: string, l10n: IL10N}, {}> {
    
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className={classNames(styles.search_box_fragment, styles.bottom, this.props.style)}>
                <hr className={styles.border}/>
                <div className={styles.headline}>
                    {helper.decodeHTML(this.props.l10n.stillDidntFindTheRightOne)}
                </div>

                <FragmentPlaceholder 
                    src={'ESPP2.ShopSearch.Web/searchbox/search-grid'}
                    ignoreErrors={true}
                    fallbackSrc={'/darkmatter-fallbacks/ESPP2.ShopSearch.Web/ssw-fallback-search-grid.html'}
                />
            </div>
        )
    }
}