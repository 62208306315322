import * as React from 'react';
import * as ReactDOM from 'react-dom';
import SearchResultPage from './searchresultpage/searchresultpage';

function hydrateSearchResultPage() {
    if (typeof window.__PRELOADED_SEARCH_RESULT_PAGE_STATE__ !== 'undefined') {
        const data = JSON.parse(JSON.stringify(window.__PRELOADED_SEARCH_RESULT_PAGE_STATE__));
        delete window.__PRELOADED_SEARCH_RESULT_PAGE_STATE__;
        ReactDOM.hydrate(
            <SearchResultPage {...data} />,
            document.getElementById('ats_searchresultpage'),
        );
    }
}

hydrateSearchResultPage();
