import React, { Component } from 'react';
import * as helper from '../../../Common/html-helper';
import FragmentPlaceholder from '../../../Common/fragment-placeholder/fragment-placeholder';
import { IL10N } from '../../../Common/l10n-keys';
import { ILanguageSwitcherFragmentProps } from '../searchresultpage.d';
import styles from './language-switcher.scss';
import classNames from 'classnames';
import { PageViewLogDataId, SearchTelemetryAjaxCaller, SearchTelemetryData } from '../SearchTelemetryAjaxCaller';

export default class LanguageSwitcher extends Component<{
    languageSwitcherProps: ILanguageSwitcherFragmentProps, 
    l10n: IL10N
}, {}> {
    switcherFragmentRef: React.RefObject<HTMLDivElement>;
    
    constructor(props) {
        super(props);
        this.switcherFragmentRef = React.createRef<HTMLDivElement>();
        this.onLanguageSwitchClicked.bind(this);
    }

    componentDidMount(): void {
        const buttons = this.switcherFragmentRef.current.getElementsByClassName('mkt-switcher-button');
        for (let index = 0; index < buttons.length; index++) {
            const button = buttons[index];
            button.addEventListener('click', this.onLanguageSwitchClicked.bind(this));
        }
    }

    private onLanguageSwitchClicked(ev): void {
        const stm = this.props.languageSwitcherProps.telemetryDataViewModel.stm;
        if (!stm) return;
        const data = new SearchTelemetryData(
            stm,
            PageViewLogDataId.getInstance().get(), 
            this.props.languageSwitcherProps.portal, 
            this.props.languageSwitcherProps.culture);
        const switchedToCulture = ev.currentTarget.dataset.culture;
        data.term = switchedToCulture;
        SearchTelemetryAjaxCaller.postData(data);
    }

    public render() {
        return (
            <div className={classNames(styles.language_switcher_fragment, this.props.languageSwitcherProps.style)}
                ref={this.switcherFragmentRef}
            >
                <div className={styles.headline}>
                    {helper.decodeHTML(this.props.l10n.searchInOtherLanguage)}
                </div>

                {this.props.languageSwitcherProps.fragment != null && (
                    <FragmentPlaceholder
                        src={this.props.languageSwitcherProps.fragment.source}
                        ignoreErrors={true}
                    />
                )}
            </div>
        );
    }
}