import * as React from 'react';
import classNames from 'classnames';
import styles from './collection-line-value.scss';
import { IFilterValueProps } from '../filterValue/filter-value.d';
import InfoIcon from '../../../Assets/svg/info';

export default class CollectionLineValue extends React.Component<IFilterValueProps> {
    constructor(props) {
        super(props);
        this.state = {};
        this.onValueClicked = this.onValueClicked.bind(this);
        this.onTooltipClicked = this.onTooltipClicked.bind(this);
    }

    public render() {
        return (
            <div className={classNames(styles.filter_colection_line_value,
                { [styles.selected_value]: this.props.isSelected,
                [styles.disabled_value]: this.props.filterPropertyValue.articlesAmount === 0})}
                data-testid='filter_colection_line_value'
                onClick={this.onValueClicked}
            >
                {this.props.filterPropertyValue.iconSrc ?
                    (
                        <div className={styles.icon_container}>
                            <img src={this.props.filterPropertyValue.iconSrc} loading='lazy'/>
                        </div >
                    )
                    : null
                }
                <div className={classNames(styles.collection_line_image,
                    {
                        [styles.es_classic]: this.props.filterPropertyValue.id === '0',
                        [styles.es_image]: this.props.filterPropertyValue.id === '1',
                        [styles.es_active]: this.props.filterPropertyValue.id === '2',
                        [styles.es_prestige]: this.props.filterPropertyValue.id === '3',
                        [styles.es_roughtough]: this.props.filterPropertyValue.id === '4',
                        [styles.es_motion]: this.props.filterPropertyValue.id === '5',
                        [styles.es_motion2020]: this.props.filterPropertyValue.id === '6',
                        [styles.es_vision]: this.props.filterPropertyValue.id === '7',
                        [styles.es_fusion]: this.props.filterPropertyValue.id === '8',
                        [styles.es_avida]: this.props.filterPropertyValue.id === '9',
                        [styles.es_basics]: this.props.filterPropertyValue.id === '10',
                        [styles.es_dynashield]: this.props.filterPropertyValue.id === '11',
                        [styles.es_industry]: this.props.filterPropertyValue.id === '19',
                        [styles.es_blackedition]: this.props.filterPropertyValue.id === '20',
                        [styles.es_motionten]: this.props.filterPropertyValue.id === '21',
                        [styles.orange]: this.props.filterPropertyValue.id === '22',
                        [styles.es_concrete]: this.props.filterPropertyValue.id === '23',
                        [styles.es_vintage]: this.props.filterPropertyValue.id === '24',
                        [styles.es_trail]: this.props.filterPropertyValue.id === '26',
                        [styles.es_iconic]: this.props.filterPropertyValue.id === '29',
                        [styles.es_ambition]: this.props.filterPropertyValue.id === '30',
                        [styles.es_motion247]: this.props.filterPropertyValue.id === '31',
                    })} />
                <div className={styles.filter_title}>
                    {this.props.filterPropertyValue.title}
                </div>
                <div className={styles.article_amount}>
                    {this.props.filterPropertyValue.articlesAmount}
                    <span className={styles.icon_tooltip}
                        data-testid='icon_tooltip'
                        onClick={this.onTooltipClicked}>
                        <InfoIcon />
                    </span>
                </div>
            </div>
        );
    }

    private onValueClicked() {
        if (this.props.isSelected)
            this.props.removeSelectedFilter(this.props.filter);
        else
            this.props.addSelectedFilter(this.props.dimension, this.props.filterPropertyValue);
    }

    private onTooltipClicked(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.props.updateTooltip(true, this.props.filterPropertyValue.tooltip, this.getTooltipImageName());
    }

    private getTooltipImageName(): string {
        let collectionName: string;
        switch (this.props.filterPropertyValue.id) {
            case '0':
                collectionName = 'classic';
                break;
            case '1':
                collectionName = 'image';
                break;
            case '2':
                collectionName = 'active';
                break;
            case '3':
                collectionName = 'prestige';
                break;
            case '4':
                collectionName = 'roughtough';
                break;
            case '5':
                collectionName = 'motion';
                break;
            case '6':
                collectionName = 'motion2020';
                break;
            case '7':
                collectionName = 'vision';
                break;
            case '8':
                collectionName = 'fusion';
                break;
            case '9':
                collectionName = 'avida';
                break;
            case '10':
                collectionName = 'basics';
                break;
            case '11':
                collectionName = 'dynashield';
                break;
            case '19':
                collectionName = 'industry';
                break;
            case '21':
                collectionName = 'motionten';
                break;
            case '23':
                collectionName = 'concrete';
                break;
            case '24':
                collectionName = 'vintage';
                break;
            case '26':
                collectionName = 'trail';
                break;
            case '29':
                collectionName = 'iconic';
                break;
            case '30':
                collectionName = 'ambition';
                break;
            case '31':
                collectionName = 'motion247';
                break;
            default:
                return null;
        }

        return 'fas_collection-' + collectionName;
    }
}
