import React from 'react';
import styles from './tileVariants.scss';
import { Component } from 'react';
import { ISalesDesignationView } from '../article-tile.d';
import classNames from 'classnames';
import { IL10N } from '../../../../Common/l10n-keys';
import AvailableSizesBtn from '../availableSizes/availableSizesBtn';
import { IScoredProduct } from '../../../productfinderresultpage/productfinderresultpage.d';

export default class TileVariants extends Component<{
    article?: ISalesDesignationView, 
    l10n: IL10N,
    product?: IScoredProduct;
    changeColorVariant(colorCode: number): void,
    tileIsHovering: boolean,
    selectedColor: number,
    toggleAvailableSizes?(): void,
    showAvailableSizesButton?: boolean,
    pfResultColorClick?(): void,
}, { mounted: boolean, onHover: boolean }> {

    private varRef: React.RefObject<HTMLDivElement>;
    private hoverDelay: ReturnType<typeof setTimeout>;

    constructor(props) {
        super(props);
        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
        this.varRef = React.createRef<HTMLDivElement>();
        this.state = {
            mounted: false,
            onHover: false
        };
    }

    componentDidMount(): void {
        this.setState({ mounted: true });
    }

    private onMouseEnter(colorCode: number): void {
        clearTimeout(this.hoverDelay);
        this.setState({ onHover: false });

        this.hoverDelay = setTimeout(() => {
            this.props.changeColorVariant(colorCode);
            this.setState({ onHover: true });
        }, 300);
    }

    private onMouseLeave(): void {
        clearTimeout(this.hoverDelay);

        this.setState({
            onHover: false
        });
    }

    public render() {
        return (
            <div className={classNames(styles.t_variants,
                { [styles.on_hover]: this.props.tileIsHovering })}
                ref={this.varRef}
                onClick={this.preventParentEventPropagation}
                data-testid='t_variants'>
                {this.renderVariants()}
                {this.renderSizeButton()}
            </div>
        );
    }

    private getIconSrc(colorCode: number): string {
        return `${this.props.l10n.cdnUrl}assets/ats/colors64px/Original/${colorCode}.png`
    }

    private openPdpOnSelectedVariantOnly(event, colorCode: number): void {
        if (this.props.selectedColor !== colorCode) {
            this.props.changeColorVariant(colorCode);
            event.stopPropagation();
        }
    }

    // click on already selected color - trigger
    private preventParentEventPropagation(event): void {
        if (!(event.target.classList.contains('fas_color_icon') || 
            event.target.classList.contains('fas_available_sizes_btn'))) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    private renderVariants() {
        if (!this.state.mounted) return null;
        if (!this.varRef.current) return null;
        if (!this.props.tileIsHovering) return null;
        if (!this.props?.article?.salesArticleVariantColors && !this.props.product?.variants || 
            this.props?.article?.salesArticleVariantColors && this.props.product?.variants) return null;
        if (this.props?.article?.salesArticleVariantColors.length <= 1 
            && this.props?.product?.variants && this.props.product?.variants.length <= 1) return null;

        const variantContainerElement = this.varRef.current;
        const containerWidth = variantContainerElement.clientWidth;
        const columns = containerWidth / 44; // 44 width of color icon element
        const numOfRowsAllowed = this.props.showAvailableSizesButton ? 1 : 2; // Single/ two row(s) for color icons;
        const iconCount = Math.floor(columns) * numOfRowsAllowed;
        const variantColors = this.props?.article?.salesArticleVariantColors || this.props?.product?.variants;

        let allColorsShown = true;
        let displayColors = [...variantColors];
        let mode = 'sdv';
        if (this.props?.product?.variants.length > 1 && !this.props?.article?.salesArticleVariantColors.length) {
            mode = 'productFinder';
        }

        if (iconCount < displayColors.length) {
            allColorsShown = false;
            displayColors = displayColors
                .slice(0, iconCount - 1)
        }

        return (<div className={styles.colors_container}>{displayColors.map((variant, index) => {
            let clrCode = 1;
            if (mode === 'sdv') {
                clrCode = variant['color'].code as number;
            } else {
                clrCode = variant['colorCode'] as number;
            }

            const clickHandler = typeof this.props.pfResultColorClick != 'function' ?
                (event) => { this.openPdpOnSelectedVariantOnly(event, clrCode); } :
                this.props.pfResultColorClick;
            return (
                <div key={index} className={classNames(styles.color_border,
                    { [styles.selected_color]: this.props.selectedColor === clrCode })}
                    onMouseEnter={() => { this.onMouseEnter(clrCode) }}
                    onMouseLeave={this.onMouseLeave}
                    onTouchEnd={clickHandler}
                    data-testid='display_color'
                    onClick={clickHandler}
                >
                    <img className={styles.color_icon}
                        src={this.getIconSrc(clrCode)}
                        loading='lazy'
                        title={mode === 'sdv' ? variant['color']['name'] : variant['title']}
                    />
                </div>
            )
        })}
            {!allColorsShown &&
                <span className={styles.additional_colors}>
                    +{(mode === 'sdv' ?
                    this.props.article.salesArticleVariantColors.length
                    : this.props.product.variants.length) - displayColors.length}
                </span>}
        </div>)
    }

    private renderSizeButton() {
        if (!this.state.mounted) return null;
        if (!this.varRef.current) return null;
        if (!this.props.tileIsHovering) return null;
        if (!this.props.showAvailableSizesButton) return null;
        return (
            <AvailableSizesBtn toggleAvailableSizes={this.props.toggleAvailableSizes} 
            btnText={this.props.l10n.availableSizes}/>
        );
    }
}
