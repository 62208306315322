import React from 'react';

const Sort2021 = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='19.80273'
        height='16.80078'
        viewBox='0 0 19.80273 16.80078'
        className='fas-sort-2021'
    >
        <path d='M7.73047,11.53027l-2.19629,2.197V.90039a.90039.90039,0,1,0-1.80078,
        0v12.8269l-2.19629-2.197A.90046.90046,0,0,0,.26367,12.80371l3.7334,
        3.7334c.0058.00586.01373.00745.01965.01306a.88437.88437,0,0,0,1.23413,
        0c.00592-.00561.01386-.0072.01966-.01306l3.7334-3.7334a.90046.90046,0,1,0-1.27344-1.27344Z' />
        <path d='M19.53906,3.99707,15.80566.26367C15.7998.25781,15.792.25623,15.786.25061a.89409.89409,0,0,
        0-.27258-.181.903.903,0,0,0-.689,0,.89409.89409,0,0,0-.27258.181c-.006.00562-.01379.0072-.01965.01306l-3.7334,
        3.7334a.90046.90046,0,1,0,1.27344,1.27344l2.19628-2.197v12.8269a.9004.9004,0,0,0,1.80079,0V3.07349l2.19628,
        2.197a.90046.90046,0,0,0,1.27344-1.27344Z' />
    </svg>
);

export default Sort2021;
