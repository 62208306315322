import * as React from 'react';
import classNames from 'classnames';
import styles from './kiosk-filter.scss';
import { Handler } from '../globalState/globalState';
import {
    AvailableFiltersState,
    IFilterModelChangedEvent
} from '../globalState/AvailableFiltersState';
import { IFilterSelectionChangedEvent, SelectedFiltersState } from '../globalState/SelectedFiltersState';
import { convertFromServiceToReactWorld } from '../globalState/ServiceSelectedFilterConverter';
import { IKioskFilterComponentProperty, IKioskFilterComponentState, KioskFilterType } from './kiosk-filter.d';

export default class KioskFilter extends React.Component<
    IKioskFilterComponentProperty, IKioskFilterComponentState> {
    private readonly availableFilterState: AvailableFiltersState;
    private readonly selectedFilterState: SelectedFiltersState;

    constructor(props: IKioskFilterComponentProperty) {
        super(props);
        this.state = {
            filterModel: props.filterModel,
            kioskFilterState: this.parseKioskType(this.props.kiosk.retailStoreFilterMode),
            userSelectedFilters: convertFromServiceToReactWorld(
                props.userSelectedFilters,
                props.filterModel),
            showLoadingSpinner: false,
        };

        this.availableFilterState = this.props.availableFilterState;
        this.selectedFilterState = this.availableFilterState.selectedFilterState;
        this.availableFilterState.registerOnStateChanged(this.onAvailableFiltersChanged.bind(this));
        this.selectedFilterState.registerOnStateChanged(this.onSelectedFiltersChanged.bind(this));
        this.toggleCheckboxChange = this.toggleCheckboxChange.bind(this);
    }

    public render() {

        const onlyOnlineString = {
             __html: this.props.l10n.kioskOnline + '&nbsp;(' + this.state.filterModel.onlyOnlineCount + ')',
        };
        const onlyStoreString = {
             __html: this.props.l10n.kioskInStore + '&nbsp;(' + this.state.filterModel.onlyStoreCount + ')',
        };
        return (
            <div className={styles.kiosk_filter}>
                <form>
                    <div className={classNames(styles.checkbox,
                        {
                            [styles.inactive]: this.state.kioskFilterState === KioskFilterType.WorkWearStoreOnly ||
                                this.state.filterModel.onlyOnlineCount === 0 &&
                                this.state.kioskFilterState !== KioskFilterType.OnlineShopOnly,
                        })}>
                        <label className={styles.kiosk_label}>
                            <span dangerouslySetInnerHTML={onlyOnlineString} />
                            <input
                                className={styles.kiosk_checkbox}
                                type='checkbox'
                                value='OnlyOnline'
                                checked={this.state.kioskFilterState === KioskFilterType.OnlineShopOnly}
                                onChange={this.toggleCheckboxChange}
                            />
                            <span className={styles.kiosk_checkmark}></span>
                        </label>
                    </div>
                    <div className={classNames(styles.checkbox,
                        {
                            [styles.inactive]: this.state.kioskFilterState === KioskFilterType.OnlineShopOnly ||
                                this.state.filterModel.onlyStoreCount === 0 &&
                                this.state.kioskFilterState !== KioskFilterType.WorkWearStoreOnly,
                        })}>
                        <label className={styles.kiosk_label}>
                            <span dangerouslySetInnerHTML={onlyStoreString} />
                            <input
                                className={styles.kiosk_checkbox}
                                type='checkbox'
                                value='OnlyStore'
                                checked={this.state.kioskFilterState === KioskFilterType.WorkWearStoreOnly}
                                onChange={this.toggleCheckboxChange}
                            />
                            <span className={styles.kiosk_checkmark}></span>
                        </label>
                    </div>
                </form>
            </div>
        );
    }

    private parseKioskType(kioskType: string): KioskFilterType {
        switch (kioskType) {
            case 'OnlyOnline':
                return KioskFilterType.OnlineShopOnly;
            case 'OnlyStore':
                return KioskFilterType.WorkWearStoreOnly;
            default:
                return KioskFilterType.None;
        }
    }

    private toggleCheckboxChange(event) {
        const newStats = this.parseKioskType(event.target.value);
        let newValueString = '';

        if (this.state.kioskFilterState === KioskFilterType.None) {
            this.setState({ kioskFilterState: newStats });
            newValueString = event.target.value;
        } else if (newStats === this.state.kioskFilterState)
            this.setState({ kioskFilterState: KioskFilterType.None });
        else
            console.error('lost state: kiosk type'); // eslint-disable-line no-console
        this.availableFilterState.selectedFilterState.changeKiosk(newValueString);
    }

    private onSelectedFiltersChanged: Handler<IFilterSelectionChangedEvent> = (event: IFilterSelectionChangedEvent) => {
        this.setState({ 
            userSelectedFilters: event.selectedFilters,
            kioskFilterState: this.parseKioskType(event.retailStoreFilterMode)
        });
    }

    private onAvailableFiltersChanged: Handler<IFilterModelChangedEvent> = (event: IFilterModelChangedEvent) => {
        this.setState({filterModel: event.filterModel});
    }
}
