import { ISelectedFilter } from './selectedFilters.d';
import { ArticleTilesAjaxCaller } from '../articleTileGrid/ArticleTilesAjaxCaller';
import { IKioskInfo } from '../categorypage/categorypage.d';
import { IFilteringResult } from '../articleTileGrid/article-tile/article-tile.d';
import TouchPointFactory from '../../Common/MPCTouchPointFactory';
import { ViewType } from '../../Common/enums';

export class ArticleListController {
    private touchPointFactory: TouchPointFactory;

    constructor(
        private articleTilesAjaxCaller: ArticleTilesAjaxCaller,
        private newArticlesLoadedCallBack: (result: IFilteringResult) => void) {
        this.touchPointFactory = TouchPointFactory.instance;
    }

    public updateArticleList(
        lastAddedFilter: ISelectedFilter,
        lastAddedFilterSource: string,
        shouldForceScrollUp: boolean,
        kiosk: IKioskInfo,
        viewType: ViewType
    ) {
        const ajaxStartTime: number = performance.now();
        this.articleTilesAjaxCaller
            .loadArticlesAfterFilterChange(kiosk, lastAddedFilter, lastAddedFilterSource, viewType)
                .then(
                    (result) => {
                        this.newArticlesLoadedCallBack(result);
                        this.touchPointFactory.createTouchPointsBatch(result.articles);
                        const ajaxEndTime: number = performance.now();
                        const timeoutDelay = Math.max(300 - (ajaxEndTime - ajaxStartTime), 0);
                        setTimeout(() => {
                            if (shouldForceScrollUp) {
                                window.shell.publishTo('ESPP.MainSidePanel.Background.ForceScrollToTop',
                                    'ESPP.MainSidePanel.Background.ForceScrollToTop');

                                window.shell.publishTo('footerToTopButtonAnimationFinished', null);
                            }
                        }, timeoutDelay);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    // (error) => {},
                );
    }

    public static AddLastAddedForTracing(
        ajaxUrl: string,
        lastAddedFilter: ISelectedFilter,
        lastAddedFilterSource: string): string {
        const url = new URL(ajaxUrl);
        url.searchParams.delete('lastAddedFilter');
        url.searchParams.delete('lastAddedFilterSource');
        if (!lastAddedFilter || !lastAddedFilterSource)
            return url.toString();

        url.searchParams.set('lastAddedFilter', lastAddedFilter.dimension.slug + ':' + lastAddedFilter.value.slug);
        url.searchParams.set('lastAddedFilterSource', lastAddedFilterSource);
        url.searchParams.sort();
        return url.toString();
    }
}
