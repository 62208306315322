import React, { Component } from 'react';
import styles from './buttonColumn.scss';
import classNames from 'classnames';
import { ISdvColor } from '../article-tile.d';
import Bookmark from '../bookmark/bookmark';
import { ViewType } from '../../../../Common/enums';
import defaultStyles from '../../../../Common/defaults.scss';

export default class ButtonColumn extends Component<{
    variant: ISdvColor, seoSlug: string,
    navigationKey: string, on_hover: boolean, 
    id: string, viewType: ViewType, gridNumber: number,
    mpcMobileTouchPointsVisible: boolean,
    IsGlobal: boolean, categoryName: string,
    bookmarkMlt: string}, {isMobileView}> {
    private readonly mpcRef = React.createRef<HTMLDivElement>();

    // max break point when hover is not working:
    private breakpoint_tablet_max_width_1199 = parseInt(defaultStyles.breakpoint_tablet_max_width_1199);

    constructor(props) {
        super(props);

        this.state = {
            isMobileView: false
        };
    }

    componentDidMount(): void {
        // for tablet devices:
        this.setState({
            isMobileView: window.innerWidth <= this.breakpoint_tablet_max_width_1199
        });
    }

    componentDidUpdate(prevProps: { variant: ISdvColor }) {
        if (this.props.variant.salesArticleVariantKey != prevProps.variant.salesArticleVariantKey) {
            if (window.shell)
                window.shell.publishTo('ManualProductComparison.UpdateTouchPoints',
                    {
                        newSavKey: this.props.variant.salesArticleVariantKey,
                        oldSavKey: prevProps.variant.salesArticleVariantKey
                    });
        }
    }    

    public render() {
        return (
            <div data-testid='t_button_column' 
                className={classNames(styles.t_button_column,
                {[styles.show_buttons]: this.props.on_hover},
                {[styles.touch_point_is_visible]: this.props.mpcMobileTouchPointsVisible})}>
                {(!this.state.isMobileView && !this.props.IsGlobal) && <Bookmark id={this.props.id}
                    variant={this.props.variant} viewType={this.props.viewType} 
                    gridNumber={this.props.gridNumber} categoryName={this.props.categoryName}
                    bookmarkMlt={this.props.bookmarkMlt}/>
                }
                <div ref={this.mpcRef} data-selected-colorid={this.props.variant.salesArticleVariantKey}
                data-testid='mpc_touchPoint_placeHolder'>
                    <div
                        className='hidden scope-ats'
                        data-service-call='mpc-scale-tile'
                        data-slug={this.props.seoSlug}
                        data-item-origin='ArticleTile'
                        data-selected-colorid={this.props.variant.salesArticleVariantKey}
                        data-displayed-in-category-navkey={this.props.navigationKey}>
                    </div>
                </div>
            </div>
        );
    }
}
