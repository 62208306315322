import * as React from 'react';
import classNames from 'classnames';
import { IFilterValueProps } from './filter-value.d';
import styles from './filter-value.scss';
import InfoIcon from '../../../Assets/svg/info';

export default class FilterValue extends React.Component<IFilterValueProps> {
    constructor(props) {
        super(props);
        this.state = {};
        this.onValueClicked = this.onValueClicked.bind(this);
        this.onTooltipClicked = this.onTooltipClicked.bind(this);
    }

    public render() {
        if (this.props.filterPropertyValue.iconSrc)
            return (
                <div className={classNames(
                    styles.filter_value,
                    {
                         [styles.selected_value]: this.props.isSelected,
                         [styles.disabled_value]: this.props.filterPropertyValue.articlesAmount === 0,
                    })}
                    data-testid='filter_value'
                     onClick={this.onValueClicked}
                >
                    <div className={styles.icon_container}>
                        <img src={this.props.filterPropertyValue.iconSrc} loading='lazy'/>
                    </div >
                    <div className={styles.filter_title}>
                        {this.props.filterPropertyValue.title}
                    </div>
                    <div className={styles.article_amount}>{this.props.filterPropertyValue.articlesAmount}</div>
                </div>
            );
        else
            return (
                <div className={classNames(
                    styles.filter_value,
                    {
                         [styles.selected_value]: this.props.isSelected,
                         [styles.disabled_value]: this.props.filterPropertyValue.articlesAmount === 0,
                         [styles.special_width]: this.props.dimension.filterPropertyValues.length === 1,
                    })}
                    data-testid='filter_value'
                     onClick={this.onValueClicked}
                >
                    {this.props.filterPropertyValue.tooltip &&
                        <div className={styles.icon_tooltip}
                            data-testid='icon_tooltip'
                            onClick={this.onTooltipClicked}>
                            <InfoIcon/>
                        </div>
                    }
                    <div className={styles.filter_title}>
                        {this.props.filterPropertyValue.title}
                    </div>
                    <div className={styles.article_amount}>{this.props.filterPropertyValue.articlesAmount}</div>
                </div>
            );
    }

    private onValueClicked() {
        if (this.props.isSelected)
            this.props.removeSelectedFilter(this.props.filter);
        else
            this.props.addSelectedFilter(this.props.dimension, this.props.filterPropertyValue);
    }

    private onTooltipClicked(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.props.updateTooltip(true, this.props.filterPropertyValue.tooltip);
    }
}
