import * as React from 'react';
import classNames from 'classnames';
import styles from './filterValuesGroup.scss';
import { IFilterValuesGroupProp, IFilterValuesGroupState } from './filterValuesGroup.d';
import { IFilterProperty } from '../../fas-bar/filter-property.d';
import FilterValue from '../filterValue/filter-value';
import ColourValue from '../colourValue/colour-value';
import CollectionLineValue from '../collectionLineValue/collection-line-value';
import GenderValue from '../genderValue/gender-value';
import { IFilterPropertyValue } from '../../fas-bar/filter-property-value.d';
import SizeValue from '../sizeValue/size-value';
import GlovesRecommendationSort from '../glovesRecommendationSort/glovesRecommendation-sort';
import * as Constants from '../../../Common/constants';
import { IFilterPropertyValuesGroup } from '../../fas-bar/filter-property-values-group.d';

export default class FilterValuesGroup extends React.Component<IFilterValuesGroupProp, IFilterValuesGroupState> {
    private fvgElementRef = React.createRef<HTMLDivElement>();
    private nextActiveGroup: string;
    private colorsLocalizationArray: { id: string; localization: string }[];

    constructor(props: IFilterValuesGroupProp) {
        super(props);
        this.state = {
            activeFilterGroup: this.lastSelectedGroupName(),
        };
    }

    public render() {
        return (
            <div ref={this.fvgElementRef}
                className={classNames(styles.fvg,
                    { [styles.fvg_content_size]: this.props.filterProperty.name.includes(Constants.SIZE) })}>
                {this.renderFilterGroupsHeaders(this.props.filterProperty)}
                {this.renderFilterGroups(this.props.filterProperty)}
            </div>
        );
    }

    private renderFilterGroupsHeaders(filterProperty: IFilterProperty): JSX.Element {
        return (
            filterProperty.filterPropertyValuesGroups &&
                filterProperty.filterPropertyValuesGroups.length > 1 ?
                <div className={styles.fvg_headers}>
                    {
                        filterProperty.filterPropertyValuesGroups.map((valuesGroup, gindex) => {
                            return (
                                <div key={gindex}
                                    onClick={this.handleFiltersGroupSelection.bind(this, valuesGroup.groupTitle)}
                                    className={classNames(styles.fvg_title,
                                        this.isGroupActive(gindex, valuesGroup) ? styles.fvg_active : '')}
                                    data-testid='fvg_title'>
                                    {valuesGroup.groupTitle}
                                </div>
                            );
                        })
                    }
                </div>
                :
                null
        );
    }

    private renderFilterGroups(filterProperty: IFilterProperty): JSX.Element {
        // prepare color localizations:
        if (filterProperty.name === Constants.COLOUR)
            this.prepareColorsLocalizationArray();

        return (
            <div className='fv_groups'> {
                filterProperty.name !== Constants.GLOVES ?
                    filterProperty.filterPropertyValuesGroups.map((valuesGroup, gindex) => {
                        const isSpecialLayout =
                            this.getSpecialLayout(valuesGroup.filterPropertyValues);
                        return (
                            <div key={gindex} className={styles.fv_group}>
                                <div className={classNames(styles.fv_group_content,
                                    this.isGroupActive(gindex, valuesGroup) ? styles.fvg_active : '',
                                    {
                                        [styles.full_width]: filterProperty.name !== Constants.COLOUR &&
                                            filterProperty.name !== Constants.COLLECTION_LINE &&
                                            !filterProperty.name.includes(Constants.SIZE) &&
                                            !filterProperty.name.includes(Constants.GENDER),
                                        [styles.collection_line]: filterProperty.name === Constants.COLLECTION_LINE,
                                    },
                                )}>
                                    {
                                        valuesGroup.filterPropertyValues.map((value, sindex) => {
                                            return (this.renderFilterValue(filterProperty, value, sindex,
                                                isSpecialLayout));
                                        })
                                    }
                                </div>
                            </div>
                        );
                    }) : <GlovesRecommendationSort
                            rateLessImportantTitle={this.props.l10n.lessImportant}
                            rateVeryImportantTitle={this.props.l10n.veryImportant}
                            gloveFilterText2={this.props.l10n.gloveFilterText2}
                            isMobile={this.props.isMobile}
                        />
            }
            </div>
        );
    }

    private prepareColorsLocalizationArray(): void {
        this.colorsLocalizationArray = [];

        this.colorsLocalizationArray.push({ id: '1', localization: this.props.l10n.shadesOfRed });
        this.colorsLocalizationArray.push({ id: '2', localization: this.props.l10n.shadesOfBlue });
        this.colorsLocalizationArray.push({ id: '3', localization: this.props.l10n.shadesOfOrange });
        this.colorsLocalizationArray.push({ id: '5', localization: this.props.l10n.shadesOfYellow });
        this.colorsLocalizationArray.push({ id: '6', localization: this.props.l10n.shadesOfGrey });
        this.colorsLocalizationArray.push({ id: '7', localization: this.props.l10n.shadesOfGreen });
        this.colorsLocalizationArray.push({ id: '8', localization: this.props.l10n.shadesOfWhite });
        this.colorsLocalizationArray.push({ id: '9', localization: this.props.l10n.shadesOfBrown });
        this.colorsLocalizationArray.push({ id: '10', localization: this.props.l10n.shadesOfViolet });
    }

    private getSpecialLayout(filterPropertyValues: IFilterPropertyValue[]): boolean {
        return filterPropertyValues.some((value) => value.title.trim().length > 3);
    }

    private isGroupActive(gindex: number, valuesGroup: IFilterPropertyValuesGroup): boolean {
        let result = false;

        if (this.state.activeFilterGroup === null && gindex === 0
            || this.state.activeFilterGroup === valuesGroup.groupTitle) {
            if (!(valuesGroup.allDisabled && this.nextActiveGroup !== valuesGroup.groupTitle)) {
                result = true;
                this.nextActiveGroup = valuesGroup.groupTitle;
            }
        } else if (!this.nextActiveGroup || this.nextActiveGroup === valuesGroup.groupTitle) {
            this.nextActiveGroup = valuesGroup.groupTitle;
            result = true;
        }

        return result;
    }

    private handleFiltersGroupSelection(groupTitle: string): void {
        this.nextActiveGroup = groupTitle;
        this.setState({ activeFilterGroup: groupTitle });
    }

    private renderFilterValue(dimension: IFilterProperty, value: IFilterPropertyValue, index: number,
        isSpecialLayout: boolean) {
        const indexOfFilter = this.props.selectedFilters.findIndex((x) =>
            x.dimension.name === dimension.name
            && x.value.id === value.id);
        const isSelected = indexOfFilter >= 0;
        const filter = isSelected ? this.props.selectedFilters[indexOfFilter] : null;

        if (dimension.name.includes(Constants.SIZE))
            return (
                <SizeValue
                    dimension={dimension}
                    addSelectedFilter={this.props.addSelectedFilter}
                    removeSelectedFilter={this.props.removeSelectedFilter}
                    filterPropertyValue={value}
                    isSelected={isSelected}
                    filter={filter}
                    key={index}
                    updateTooltip={this.props.updateTooltip}
                    isSpecialLayout={isSpecialLayout}
                    filterTitleMlt={''}
                />
            );
        if (dimension.name === Constants.COLOUR) {
            const colorTitleMlt = this.colorsLocalizationArray.find((elm) => elm.id === value.id);
            let localization = '';

            if (colorTitleMlt)
                localization = colorTitleMlt.localization;

            return (
                <ColourValue
                    dimension={dimension}
                    addSelectedFilter={this.props.addSelectedFilter}
                    removeSelectedFilter={this.props.removeSelectedFilter}
                    filterPropertyValue={value}
                    isSelected={isSelected}
                    filter={filter}
                    key={index}
                    updateTooltip={this.props.updateTooltip}
                    filterTitleMlt={localization}
                />
            );
        }
        if (dimension.name === Constants.COLLECTION_LINE)
            return (
                <CollectionLineValue
                    dimension={dimension}
                    addSelectedFilter={this.props.addSelectedFilter}
                    removeSelectedFilter={this.props.removeSelectedFilter}
                    filterPropertyValue={value}
                    isSelected={isSelected}
                    filter={filter}
                    key={index}
                    updateTooltip={this.props.updateTooltip}
                    filterTitleMlt={''}
                />
            );
        if (dimension.name === Constants.GENDER)
            return (
                <GenderValue
                    dimension={dimension}
                    addSelectedFilter={this.props.addSelectedFilter}
                    removeSelectedFilter={this.props.removeSelectedFilter}
                    filterPropertyValue={value}
                    isSelected={isSelected}
                    filter={filter}
                    key={index}
                    updateTooltip={this.props.updateTooltip}
                    filterTitleMlt={''}
                />
            );
        else
            return (
                <FilterValue
                    dimension={dimension}
                    addSelectedFilter={this.props.addSelectedFilter}
                    removeSelectedFilter={this.props.removeSelectedFilter}
                    filterPropertyValue={value}
                    isSelected={isSelected}
                    filter={filter}
                    key={index}
                    updateTooltip={this.props.updateTooltip}
                    filterTitleMlt={''}
                />
            );
    }

    private lastSelectedGroupName(): string {
        if (this.props.selectedFilters.length > 0) {
            const selectedValue = this.props.selectedFilters.filter((value) =>
                value.dimension.name === this.props.filterProperty.name);
            return selectedValue.length > 0 ? selectedValue.pop().value.groupTitle : null;
        } else
            return null;
    }
}
