import React from 'react';
/* tslint:disable */
const IconHerren = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 31">
        <g
            data-name="Ebene 2"
            transform="translate(0,0.00397971)">
            <g
                data-name="Ebene 1">
                <path
                    d="m 11,11 a 8,8 0 1 1 -8,8 8,8 0 0 1 8,-8 M 11,8 A 11,11 0 1 0 22,19 11,11 0 0 0 11,8 Z" />
                <rect
                    x="16.290001"
                    y="4.8000002"
                    width="14.82"
                    height="2.99"
                    transform="rotate(-45,23.697186,6.2943041)" />
                <rect
                    x="26.950001"
                    y="0.059999999"
                    width="2.99"
                    height="11.98" />
                <rect
                    x="17.959999"
                    y="0.059999999"
                    width="11.98"
                    height="2.99" />
            </g>
        </g>
    </svg>
);

export default IconHerren;
