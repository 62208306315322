import React, { Component } from 'react';
import FragmentPlaceholder from '../../../Common/fragment-placeholder/fragment-placeholder';
import { ISearchInsteadBoxProps, ISearchInsteadBoxState, ISoldOutArticle } from '../searchresultpage.d';
import styles from './searchbox.scss';
import classNames from 'classnames';

export default class TopSearchbox extends Component<ISearchInsteadBoxProps, ISearchInsteadBoxState> {
    constructor(props: ISearchInsteadBoxProps) {
        super(props);
        this.state = {
            showTopSearchbox: this.props.topPageSearchBoxFragment != null || 
                this.props.htmlEncodedAppropriateResultNotFoundText != '' || 
                this.props.soldOutArticles?.length > 0,
            showSingleAltHeadline: this.props.htmlEncodedAppropriateResultNotFoundText != '' || 
                this.props.htmlEncodedSearchInsteadText != '',
        };
    }

    public render() {
        return (
            <>
                {this.props.style === 'single-alternative-search-result' ?
                    this.state.showSingleAltHeadline && (
                        <div className={classNames(styles.single_alternative_search_result_text, this.props.style)}>
                            {this.getTextElement(this.getHtmlEncodedText())}
                        </div>
                    )
                    : <>
                        {this.state.showTopSearchbox && (
                            <div className={classNames(styles.search_box_fragment, 'top', `fas_${this.props.style}`)}>
                                {this.props.htmlEncodedAppropriateResultNotFoundText && (
                                    this.getTextElement(this.props.htmlEncodedAppropriateResultNotFoundText)
                                )}

                                {this.props.soldOutArticles?.length > 0 && (
                                    <div className={styles.search_result_sold_out}>
                                        <span className={styles.sold_out_info}>
                                            {this.props.l10n.alreadySoldOut}
                                        </span>
                                        {this.props.soldOutArticles.map((article, index) => {
                                            return (
                                                <span 
                                                    key={index}
                                                    className={styles.sold_out_article_info} 
                                                    dangerouslySetInnerHTML={{ 
                                                        __html: this.getSoldOutArticleInfo(article) 
                                                    }}>
                                                </span>
                                            );
                                        })}
                                    </div> 
                                )}

                                {this.props.topPageSearchBoxFragment != null && (
                                    <FragmentPlaceholder 
                                        src={this.props.topPageSearchBoxFragment.source}
                                        ignoreErrors={true}
                                        fallbackSrc={this.props.topPageSearchBoxFragment.fallbackSource}
                                    />
                                )}
                            </div>
                        )}

                        {this.props.htmlEncodedSearchInsteadText && (
                            <div className={classNames(styles.single_alternative_search_result_text, this.props.style)}>
                                {this.getTextElement(this.props.htmlEncodedSearchInsteadText)}
                            </div>
                        )}
                    </>
                }
            </>
        );
    }

    private getHtmlEncodedText(): string {
        let htmlEncodedText = '';
        if (this.props.htmlEncodedAppropriateResultNotFoundText) {
            htmlEncodedText = this.props.htmlEncodedAppropriateResultNotFoundText;
        }
        if (this.props.htmlEncodedSearchInsteadText) {
            htmlEncodedText += ' ' + this.props.htmlEncodedSearchInsteadText;
        }
        return htmlEncodedText;
    }

    private getTextElement(htmlEncodedText: string) {
        return (
            <div className={styles.headline} dangerouslySetInnerHTML={{ __html: htmlEncodedText }}></div>
        );
    }

    private getSoldOutArticleInfo(article: ISoldOutArticle): string {
        const articleInfo = 
            this.props.l10n.startQuotationMark
            + article.articleName
            + this.props.l10n.endQuotationMark
            + ' - '
            + this.props.l10n.catalogNoLesserAcronym
            + this.props.l10n.colon
            + ' '
            + article.salesArticleNo;
        return articleInfo;
    }
}