import { IFilterModel } from '../globalState/filterModel';
import { ISelectedFilter, IServiceSelectedFilter } from '../globalState/selectedFilters';
import { IL10N } from '../../Common/l10n-keys';
import { IKioskInfo } from '../categorypage/categorypage.d';
import { AvailableFiltersState } from '../globalState/AvailableFiltersState';

export interface IKioskFilterComponentProperty {
    viewType: string;
    culture: string;
    culturePrefix: string;
    navigationKey: string;
    seoSlug: string;
    categoryPath: string;
    filterModel: IFilterModel;
    userSelectedFilters: IServiceSelectedFilter[];
    l10n: IL10N;
    searchTerm: string;
    isMobile: boolean;
    isAlternative: boolean;
    portal: string;
    kiosk: IKioskInfo;
    availableFilterState: AvailableFiltersState;
}

export interface IKioskFilterComponentState {
    userSelectedFilters: ISelectedFilter[];
    filterModel: IFilterModel;
    showLoadingSpinner: boolean;
    kioskFilterState: KioskFilterType;
}

export enum KioskFilterType {
    None,
    WorkWearStoreOnly,
    OnlineShopOnly,
}
