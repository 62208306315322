import * as React from 'react';
import Rating from 'react-rating';
import classNames from 'classnames';
import styles from './rate.scss';
import { IRateProperty } from './rate.d';
import XIcon from '../../../../Assets/svg/x';
import { SortContext } from '../../../globalState/sortContextProvider';
import { ISortContext } from '../../../globalState/sortContextProvider.d';

export default class Rate extends React.Component<IRateProperty> {
    static contextType = SortContext;

    private emptySymbols: JSX.Element[];
    private fullSymbols: JSX.Element[];

    constructor(props, context) {
        super(props, context);

        this.clearSelectedRate = this.clearSelectedRate.bind(this);
        this.onRateChange = this.onRateChange.bind(this);

        this.init();
    }

    public render() {
        return (
                    <div className={styles.rating}>
                        <div className={styles.rate_title}>
                            <span className={styles.title_text}>
                                {this.props.glovesFilterSort.title}
                            </span>
                        </div>
                        <div className={styles.rp_container}>
                            {this.renderClearSelectedRatingBtn()}
                            <Rating className={styles.rating_points}
                                    onChange={this.onRateChange}
                                    initialRating={this.props.glovesFilterSort.glovesRate}
                                    emptySymbol={this.emptySymbols}
                                    fullSymbol={this.fullSymbols}
                                    readonly={this.props.mobileTouchStarted}/>
                        </div>
                    </div>
        );
    }

    private renderClearSelectedRatingBtn(): JSX.Element {
        return (
            this.props.glovesFilterSort.glovesRate > 0 ? <span className={styles.clear_selected_rating_btn}
                data-testid='clear_selected_rating_btn'
                onClick={this.clearSelectedRate}><XIcon /></span> :
                <span className={styles.btn_place_holder}></span>
        );
    }

    private init(): void {
        this.emptySymbols = [];
        this.fullSymbols = [];

        this.emptySymbols.push(<div className={styles.rate_point}>
            <div className={styles.rp_shape_1}></div>
        </div>);
        this.emptySymbols.push(<div className={styles.rate_point}>
            <div className={styles.rp_shape_2}></div>
        </div>);
        this.emptySymbols.push(<div className={styles.rate_point}>
            <div className={styles.rp_shape_3}></div>
        </div>);
        this.emptySymbols.push(<div className={styles.rate_point}>
            <div className={styles.rp_shape_4}></div>
        </div>);
        this.emptySymbols.push(<div className={styles.rate_point}>
            <div className={styles.rp_shape_5}></div>
        </div>);

        this.fullSymbols.push(<div className={classNames(styles.rate_point, styles.rate_point_hover)}>
            <div className={styles.rp_shape_1}></div>
        </div>);
        this.fullSymbols.push(<div className={classNames(styles.rate_point, styles.rate_point_hover)}>
            <div className={styles.rp_shape_2}></div>
        </div>);
        this.fullSymbols.push(<div className={classNames(styles.rate_point, styles.rate_point_hover)}>
            <div className={styles.rp_shape_3}></div>
        </div>);
        this.fullSymbols.push(<div className={classNames(styles.rate_point, styles.rate_point_hover)}>
            <div className={styles.rp_shape_4}></div>
        </div>);
        this.fullSymbols.push(<div className={classNames(styles.rate_point, styles.rate_point_hover)}>
            <div className={styles.rp_shape_5}></div>
        </div>);
    }

    private clearSelectedRate(): void {
        const sortContext: ISortContext = this.context;
        sortContext.setGloveRating(this.props.glovesFilterSort, 0, !this.props.isMobile,
            !this.props.isMobile, !this.props.isMobile);
    }

    private onRateChange(rateParam: number): void {
        const sortContext: ISortContext = this.context;
        sortContext.setGloveRating(this.props.glovesFilterSort, rateParam, !this.props.isMobile,
            !this.props.isMobile, !this.props.isMobile);
    }
}
