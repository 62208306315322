import { ISortContextProviderState } from './sortContextProvider.d';
import  { ISortContextAction } from './sortContextReducer.d';
import { SortType } from '../sort-side-content/sort-side-content.d';
import { ViewType, SortContextActionType } from '../../Common/enums';
import { IL10N } from '../../Common/l10n-keys';
import { IFilterProperty } from '../fas-bar/filter-property.d';

export function initState({ viewType, l10n, glovesFilter, initialSortUrlParam }: {
    l10n: IL10N,
    viewType: ViewType,
    glovesFilter: IFilterProperty,
    initialSortUrlParam: string }
): ISortContextProviderState {
    const initialState = {
        sortPropertyList: [
            {
                selected: false,
                sortType: SortType.Popularity,
                title: l10n.popularity,
                urlValue: viewType !== 'Search' ? null : 'popularity',
            },
            {
                selected: false,
                sortType: SortType.NewestFirst,
                title: l10n.newestFirst,
                urlValue: 'newest',
            },
            {
                selected: false,
                sortType: SortType.PriceDescending,
                title: l10n.priceDescending,
                urlValue: '-price',
            },
            {
                selected: false,
                sortType: SortType.PriceAscending,
                title: l10n.priceAscending,
                urlValue: 'price',
            },
        ],
        glovesSortPropValues: glovesFilter ? glovesFilter.filterPropertyValues : [],
    };

    if (!initialSortUrlParam && viewType !== 'Search') {
        const popSort = initialState.sortPropertyList.find(item => item.sortType === SortType.Popularity);
        if (popSort) {
            popSort.selected = true;
        }
    } else {
        const idx = initialState.sortPropertyList.findIndex(sortProperty => sortProperty.urlValue === initialSortUrlParam);
        if (initialState.sortPropertyList[idx]) {
            initialState.sortPropertyList[idx].selected = true;
        }

        if (initialState.glovesSortPropValues && initialState.glovesSortPropValues.length > 0) {
            initialState.glovesSortPropValues.forEach((gsProperty) => {
                gsProperty.glovesRate = 0;
                // if there is gloves sort parameters, check url sort parameters:
                if (initialSortUrlParam) {
                    initialSortUrlParam.split(new RegExp(',|%2c')).forEach((sortParam) => {
                        if (sortParam.indexOf(gsProperty.slug) >= 0) {
                            gsProperty.glovesRate = Number(sortParam.substr(-1));
                        }
                    });
                }
            });
        }
    }

    return initialState;
}

function sortContextReducer(
    state: ISortContextProviderState,
    action: ISortContextAction
): ISortContextProviderState {
    switch (action.type) {
        case SortContextActionType.SetSortSelected: {
            const newSortPropertyList = state.sortPropertyList.map((item) => {
                item.selected = item.sortType === action.sortType;
                return item;
            });

            const newGlovesSortPropValues = state.glovesSortPropValues.map((gsProperty) => {
                return { ...gsProperty, glovesRate: 0 };
            });

            return { ...state, sortPropertyList: newSortPropertyList, glovesSortPropValues: newGlovesSortPropValues };
        }

        case SortContextActionType.SetGloveRating: {
            const newGlovesSortPropValues = state.glovesSortPropValues.map((prop) => {
                let result = prop;
                if (prop.slug === action.gloveSlug) {
                    result = {...prop, glovesRate: action.gloveRating };
                }
                return result;
            });

            const newSortPropertyList = state.sortPropertyList.map((item) => {
                item.selected = false;
                return item;
            });

            return { ...state, sortPropertyList: newSortPropertyList, glovesSortPropValues: newGlovesSortPropValues };
        }
        
        
        case SortContextActionType.ResetRegularSortValues: {
            const newSortPropertyList = state.sortPropertyList.map(prop => (
                { ...prop, selected: prop.sortType === SortType.Popularity })
            );

            return { ...state, sortPropertyList: newSortPropertyList };
        }

        case SortContextActionType.ResetGloveSortValues: {
            const newGlovesSortPropValues = state.glovesSortPropValues.map((gsProperty) => {
                return {...gsProperty, glovesRate: 0};
            });

            return { ...state, glovesSortPropValues: newGlovesSortPropValues };
        }

        default: {
            throw Error('Unknown SortContextReducer action: ' + action.type);
        }
    }

}

export default sortContextReducer;
