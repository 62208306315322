import React from 'react';

const Filter2021 = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width='19.80078'
        height='18.90039'
        viewBox='0 0 19.80078 18.90039'
        className='fas-filter-2021'
    >
        <path d='M.90039,3.8501H2.93848a2.93014,2.93014,0,0,0,5.58984,0H18.90039a.89991.89991,0,1,0,
        0-1.79981H8.52832a2.93014,2.93014,0,0,0-5.58984,0H.90039a.89991.89991,0,1,0,0,1.79981Zm4.833-2.44971A1.54981,
        1.54981,0,1,1,4.18359,2.9502,1.55165,1.55165,0,0,1,5.7334,1.40039Z' />
        <path d='M18.90039,8.55029H17.14648a2.93014,2.93014,0,0,0-5.58984,0H.90039a.89991.89991,0,1,0,0,
        1.79981H11.55664a2.93014,2.93014,0,0,0,5.58984,0h1.75391a.89991.89991,0,1,0,0-1.79981ZM14.35156,11a1.54981,
        1.54981,0,1,1,1.54981-1.5498A1.55165,1.55165,0,0,1,14.35156,11Z' />
        <path d='M18.90039,15.05029H10.53223a2.93015,2.93015,0,0,0-5.58985,0H.90039a.89991.89991,0,1,0,0,
        1.79981h4.042a2.93015,2.93015,0,0,0,5.58985,0h8.36816a.89991.89991,0,1,0,0-1.79981ZM7.7373,17.5a1.54981,
        1.54981,0,1,1,1.54981-1.5498A1.55165,1.55165,0,0,1,7.7373,17.5Z' />
    </svg>
);

export default Filter2021;
