import React, { Component } from 'react';
import { ISuggestionLink } from '../searchresultpage.d';
import styles from './suggestion-link.scss';
import { PageViewLogDataId, SearchTelemetryAjaxCaller, SearchTelemetryData } from '../SearchTelemetryAjaxCaller';

export default class SuggestionLinks extends Component<ISuggestionLink, {}> {

    constructor(props: ISuggestionLink) {
        super(props);
    }

    public render() {
        const stm = this.props.telemetryDataViewModel ? this.props.telemetryDataViewModel.stm : null;
        return (
            <div className={styles.suggestion_link_wrapper}>
                <a className={styles.suggestion_link} href={this.props.target} data-stm={stm} 
                   onClick={()=>this.handleTelemetry(stm)}>
                    {this.props.searchTerm.toLowerCase()}
                </a>
            </div>
        )
    }

    private handleTelemetry(stm: string): void {
        if(!stm) return;
        const data = new SearchTelemetryData(
            stm,
            PageViewLogDataId.getInstance().get(), 
            this.props.portal,
            this.props.culture,
            );
        SearchTelemetryAjaxCaller.postData(data);
    }
}