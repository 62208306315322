import * as React from 'react';
import styles from './content.scss';
import classnames from 'classnames';
import { IContentProps } from './content.d';
import { ContentType } from '../sidePanel/contentType';
import * as helper from '../../../Common/html-helper';
import FragmentPlaceholder from '../../../Common/fragment-placeholder/fragment-placeholder';
import FasSideContent from '../../fas-side-content/fas-side-content';
import SortSideComponent from '../../sort-side-content/sort-side-content';

export default class Content extends React.Component<IContentProps,
    { currentState: ContentType }> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div className={styles.msp_content} id='msp-content'>
                {this.props.filterContentViewModel &&
                    <div data-testid={'sort-content'}
                        className={classnames(styles.sort_content,
                            {[styles.hidden]: !this.isSortContent()})}>

                            <SortSideComponent
                                {...this.props.filterContentViewModel}
                                initialFilterModel={this.props.filterContentViewModel.filterModel}
                                initialUserSelectedFilters={this.props.filterContentViewModel.userSelectedFilters}
                                globalState={this.props.globalState}
                            />
                    </div>
                }
                {this.props.filterContentViewModel &&
                    <div data-testid={'filter-content'}
                        className={classnames(styles.scope_fas, styles.filter_content,
                            {[styles.hidden]: !this.isFilterContent()})}>
                        <div id='fas_scope-fas-side-content' className={styles.scope_fas}>
                            <FasSideContent
                                {...this.props.filterContentViewModel}
                                initialFilterModel={this.props.filterContentViewModel.filterModel}
                                initialUserSelectedFilters={this.props.filterContentViewModel.userSelectedFilters}
                                globalState={this.props.globalState}
                            />
                        </div>
                    </div>
                }
                {
                    this.props.shouldShowProductFinder && this.props.productFinderServicesUpAndRunning ?
                        (
                            <div id='product-finder-content'
                                data-testid={'product-finder-content'}
                                className={classnames(styles.product_finder_content,
                                    this.showProductFinder())}>
                                <FragmentPlaceholder
                                    src={`/${this.props.productFinderKey}`}
                                    ignoreErrors={true}
                                />
                            </div>
                        ) : (
                            <div className={styles.fbt_container}>
                                <div className={styles.fall_back_text}>{
                                    helper.decodeHTML(this.props.localizations.finderNotAvailableText)}
                                </div>
                            </div>
                        )
                }
            </div>
        );
    }

    public isFilterContent(): boolean {
        return this.props.initialState === ContentType.FilterContent;
    }

    public isSortContent(): boolean {
        return this.props.initialState === ContentType.SortContent;
    }

    public showProductFinder(): string {
        if (this.props.initialState !== ContentType.FilterContent &&
            this.props.initialState !== ContentType.SortContent)
            return '';
        return styles.hidden;
    }
}
