import { IFilterSelectionChangedEvent, SelectedFiltersState, FilterSelectionChangedType } from './SelectedFiltersState';
import { AvailableFiltersState } from './AvailableFiltersState';
import { Handler, GlobalState } from './globalState';
import { convertFromReactToServiceWorld } from './ServiceSelectedFilterConverter';
import { IFilterModel } from './filterModel.d';
import * as helper from '../../Common/html-helper';
import { IKioskInfo } from '../categorypage/categorypage.d';

export class FilterAjaxUpdateController {

    public readonly kiosk: IKioskInfo;

    private readonly navigationKey: string;
    private readonly availableFiltersState: AvailableFiltersState;
    private readonly searchTerm: string;
    public static IsRequestRunning = false;

    constructor(
        private globalState: GlobalState,
        navigationKey: string,
        availableFiltersState: AvailableFiltersState,
        selectedFilterState: SelectedFiltersState,
        searchTerm: string,
        kiosk: IKioskInfo,
    ) {
        selectedFilterState.registerOnStateChanged(this.onFiltersChanged.bind(this));

        this.navigationKey = navigationKey;
        this.availableFiltersState = availableFiltersState;
        this.searchTerm = searchTerm;
        this.kiosk = kiosk;
    }

    private onFiltersChanged: Handler<IFilterSelectionChangedEvent> = (event: IFilterSelectionChangedEvent) => {
        if (typeof window === 'undefined')
            return;

        // For GlobalUpdate, no need to do the ajax call
        if (event.type === FilterSelectionChangedType.GlobalUpdate)
            return;

        if (event.type === FilterSelectionChangedType.RetailStoreFilterModeChanged)
            this.kiosk.retailStoreFilterMode = event.retailStoreFilterMode;

        const apiUrl = this.urlBuilder().toString();
        const serviceSelectedFilters = convertFromReactToServiceWorld(event.selectedFilters);
        this.globalState.Spinner.startSpinner();
        FilterAjaxUpdateController.IsRequestRunning = true;
        fetch(apiUrl,
            {
                body: JSON.stringify(serviceSelectedFilters),
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            },
        ).then((res) => res.json())
            .then(
                (result: IFilterModel) => {
                    FilterAjaxUpdateController.IsRequestRunning = false;
                    this.availableFiltersState.onNewFilterModel(result);
                    this.globalState.Spinner.stopSpinner();
                    // global filters has to be updated on every filter value select/deselect:
                    // WARNING!!!! make an if condition, for mobile, we don't do global update
                    const shouldNotGlobalUpdate: boolean = helper.isMobileShop_DontUseFromReact() &&
                        event.type !== FilterSelectionChangedType.GlobalFilterRemoved &&
                        event.type !== FilterSelectionChangedType.GlobalAllFilterRemoved;
                    if (!shouldNotGlobalUpdate)
                        this.availableFiltersState.updateGlobalState();
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    FilterAjaxUpdateController.IsRequestRunning = false;
                    this.availableFiltersState.onErrorWhileUpdating(error);
                    this.availableFiltersState.restoreToGlobalState();
                    this.globalState.Spinner.stopSpinner();
                },
            );
    }

    // Url builder
    private urlBuilder(): URL {
        const apiUrl = this.globalState.getMagicLink
            + 'api/articletileservice/calculateFilters';
        const url = new URL(apiUrl);
        if (this.searchTerm !== null && (typeof this.searchTerm !== 'undefined'))
            url.searchParams.set('query', this.searchTerm);
        else
            url.searchParams.set('navigationKey', this.navigationKey);

        if (this.kiosk && (typeof this.kiosk !== 'undefined')) {
            url.searchParams.set('wwsID', this.kiosk.retailStoreNo.toString());
            if (this.kiosk.retailStoreFilterMode)
                url.searchParams.set('kioskType', this.kiosk.retailStoreFilterMode);
        }
        return url;
    }
}
