/* eslint-disable max-len */

import React from 'react';

const Productfinder2021 = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg' width='22.80078' height='22.80078' viewBox='0 0 22.80078 22.80078' className='finder-2021'>
        <path
            d='M21.90039,10.5H20.384a9.04475,9.04475,0,0,0-8.08325-8.08325V.90039a.90039.90039,0,0,0-1.80078,0V2.41675A9.04475,9.04475,0,0,0,2.41675,
                10.5H.90039a.90039.90039,0,0,0,0,1.80078H2.41675A9.04475,9.04475,0,0,0,10.5,20.384v1.51636a.90039.90039,0,1,0,1.80078,0V20.384A9.04475,
                9.04475,0,0,0,20.384,12.30078h1.51636a.90039.90039,0,0,0,0-1.80078Zm-9.59961,8.06665V16.68457a.90039.90039,0,0,0-1.80078,0v1.88208a7.23382,
                7.23382,0,0,1-6.26587-6.26587H6.11621a.90039.90039,0,1,0,0-1.80078H4.23413A7.23383,7.23383,0,0,1,10.5,4.23413V6.11621a.90039.90039,0,0,0,1.80078,
                0V4.23413A7.23382,7.23382,0,0,1,18.56665,10.5H16.68457a.90039.90039,0,1,0,0,1.80078h1.88208A7.23381,7.23381,0,0,1,12.30078,18.56665Z' />
    </svg>
);

export default Productfinder2021;
