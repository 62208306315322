import * as React from 'react';
import * as FragmentPlaceholderTypes from './fragment-placeholder.d';

export default class FragmentPlaceholder extends React.Component<FragmentPlaceholderTypes.IFragmentPlaceholderProps> {

    constructor(props) {
        super(props);
    }

    private createFragmentPlaceholder() {
        const ignoreErrors: string = this.props.ignoreErrors ? 'true' : 'false';
        const fallback: string = this.props.fallbackSrc != null ? ` fallback-src='${this.props.fallbackSrc}'` : '';
        const timeout: string = this.props.timeout && this.props.timeout > 0 ? ` timeout='${this.props.timeout}'` : '';
        const dclass: string = this.props.dclass != null ? ` dclass='${this.props.dclass}'` : '';

        return { __html: `<!--#fragment src="${this.props.src}" ignore-errors="${ignoreErrors}"${fallback}${timeout}
            ${dclass}-->`};
    }

    public render() {
        return (<div
            className='fas_fragment_ph'
            suppressHydrationWarning={true}
            dangerouslySetInnerHTML={this.createFragmentPlaceholder()} />);
    }
}
