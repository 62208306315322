import React, { Component } from 'react';
import styles from './article-tile-grid.scss';
import ArticleTile from './article-tile/article-tile';
import { IArticleTileGridProps } from './article-tile-grid.d';
import NoResult from '../noResult/noResult';
import { ISalesArticleVariantsLookup, ISalesDesignationView, ISdvColor, ISize } from './article-tile/article-tile.d';
import { ICategory } from '../categorypage/categorypage.d';
import AvailableSizesOverlayModal from './article-tile/availableSizes/availableSizesOverlayModal';
import { ScrollLock } from '../../../Helper/scrollLock';

export default class ArticleTileGrid extends Component<IArticleTileGridProps,
    {
        mpcMobileTouchPointsVisible: boolean,
        showAvailableSizesOverlay: boolean,
        mounted: boolean,
        salesArticleVariantColors: ISdvColor[], // for available sizes
        dcvColorCode: number, // for available sizes
        sizes: ISize[], // for available sizes
        salesArticleVariantsLookup: ISalesArticleVariantsLookup[],
        masterArticleNo?: number, // for available sizes,
    }> {

    private scrollLock: ScrollLock;

    constructor(props) {
        super(props);

        this.scrollLock = ScrollLock.instance;

        this.state = {
            mpcMobileTouchPointsVisible: false,
            showAvailableSizesOverlay: false,
            mounted: false,
            salesArticleVariantColors: [],
            dcvColorCode: null,
            sizes: [],
            salesArticleVariantsLookup: [],
        }

        this.toggleAvailableSizes = this.toggleAvailableSizes.bind(this);
        this.showAvailableSizesOverlay = this.showAvailableSizesOverlay.bind(this);
        this.handlePromoTileClick = this.handlePromoTileClick.bind(this);
    }

    componentDidMount(): void {
        // event handler is needed for tablet and mobile devices, desktop devices to not have publish of this event
        if ((typeof window !== 'undefined' && typeof window.shell !== 'undefined')) {
            window.shell.subscribeTo('ArticleTileService.Mobile.ToggleTouchPointOnTile', () => {
                this.setState({
                    mpcMobileTouchPointsVisible: !this.state.mpcMobileTouchPointsVisible
                });
            }, 'ArticleTileService.Mobile.ToggleTouchPointOnTile_ForAts');
        }

        this.setState({
            mounted: true
        });
    }

    componentDidUpdate(prevProps: IArticleTileGridProps): void {
        const prevMAList = prevProps.articles.map(article => article.masterArticleNo).toString();
        const maList = this.props.articles.map(article => article.masterArticleNo).toString();
        const isArticalListUpdated = prevMAList !== maList;
        let promTileUpdated = false;

        if (prevProps.originalPromotileHtmlstring && this.props.originalPromotileHtmlstring) {
            const prevPromtileHtml = JSON.stringify([...prevProps.originalPromotileHtmlstring]);
            const promtileHtml = JSON.stringify([...this.props.originalPromotileHtmlstring]);
            promTileUpdated = prevPromtileHtml !== promtileHtml;
        }

        if (isArticalListUpdated || promTileUpdated) {
            this.replacePromotionTileContent(500);
        }
    }

    componentWillUnmount(): void {
        this.setState({
            mounted: false
        });
    }

    private replacePromotionTileContent(timer: number) {
        if (this.props.originalPromotileHtmlstring && this.props.originalPromotileHtmlstring.size > 0) {
            const promotileKeys = this.props.articles.filter(article =>
                article.promotionTileKey !== null && article.promotionTileKey !== undefined);

            promotileKeys.forEach(key => {
                const promotilePlaceHolder =
                    document.querySelectorAll(`[data-container-tile-key="${key.promotionTileKey}"]`);
                const html = this.props.originalPromotileHtmlstring.get(key.promotionTileKey);
                promotilePlaceHolder.forEach(tile => {
                    tile.innerHTML = html;
                    setTimeout(() => {
                        if (window.shell) {
                            window.shell.publishTo('ESPP.PromotionTile.Init', { newContext: tile });
                        }
                    }, timer);
                });
            })
        }
    }

    private toggleAvailableSizes(): void {
        this.setState({ showAvailableSizesOverlay: !this.state.showAvailableSizesOverlay },
            () => {
                if (this.state.showAvailableSizesOverlay)
                    this.scrollLock.lock();
                else
                    this.scrollLock.unlock();
            });
    }

    private showAvailableSizesOverlay(salesArticleVariantColors: ISdvColor[],
        dcvColorCode: number, sizes: ISize[],
        salesArticleVariantsLookup: ISalesArticleVariantsLookup[],
        masterArticleNo: number): void {

        this.setState({
            salesArticleVariantColors: salesArticleVariantColors,
            dcvColorCode: dcvColorCode,
            sizes: sizes,
            salesArticleVariantsLookup: salesArticleVariantsLookup,
            masterArticleNo: masterArticleNo
        }, () => {
            this.toggleAvailableSizes();
        });
    }

    private handlePromoTileClick(tileId: string, pageNo: number): void {
        this.props.globalState.urlController.updateUrlForPaging(pageNo, tileId);
    }

    public render() {
        return (
            <>
                {
                    this.state.mounted &&
                    <AvailableSizesOverlayModal
                        l10n={this.props.l10n}
                        salesArticleVariantColors={this.state.salesArticleVariantColors}
                        toggleAvailableSizes={this.toggleAvailableSizes}
                        sizes={this.state.sizes}
                        salesArticleVariantsLookup={this.state.salesArticleVariantsLookup}
                        showAvailableSizesOverlay={this.state.showAvailableSizesOverlay}
                        dcvColorCode={this.state.dcvColorCode}
                        masterArticleNo={this.state.masterArticleNo}
                    />
                }
                {this.props.articles &&
                    this.props.articles.length > 0 ?
                    <div ref={this.props.gridRef ? this.props.gridRef : null}
                        className={styles.article_tile_grid_root} suppressHydrationWarning={true}>
                        {
                            this.props.articles.map((articleTile, index) => {

                                if (articleTile.isPromotionTile) {
                                    return (<div className={styles.promo_tile_container}
                                            id={'PromotionTile_' + articleTile.promotionTileKey}
                                            data-promotion-tile-index={articleTile.promotionTileIndex}
                                            data-container-tile-key={articleTile.promotionTileKey}
                                            onClick={() => {
                                                this.handlePromoTileClick(articleTile.tileId, articleTile.pageNo)}
                                            }
                                            suppressHydrationWarning={true}
                                            key={index} 
                                            ref={articleTile.elementRef}/>)
                                } else {
                                    return (
                                        <ArticleTile
                                            article={articleTile}
                                            key={index + articleTile.pdpLinkDefaultColor}
                                            category={this.getCategory(articleTile)}
                                            isMobile={this.props.isMobile}
                                            isGlobal={this.props.isGlobal}
                                            portal={this.props.portal}
                                            culture={this.props.culture}
                                            isFilterDropDownOpen={this.props.isFilterDropDownOpen}
                                            l10n={this.props.l10n}
                                            currencyInformation={this.props.currencyInformation}
                                            viewType={this.props.viewType}
                                            gridNumber={this.props.gridNumber}
                                            mpcMobileTouchPointsVisible={this.state.mpcMobileTouchPointsVisible}
                                            articleTilesAjaxCaller={this.props.articleTilesAjaxCaller}
                                            addItemOriginToPdpLink={this.props.addItemOriginToPdpLink}
                                            showAvailableSizesOverlay={this.showAvailableSizesOverlay}
                                            urlController={this.props.globalState.urlController}
                                            singleArticleView={this.props.singleArticleView}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                    : <NoResult
                        l10n={this.props.l10n}
                        viewType={this.props.viewType}
                        isMobile={this.props.isMobile}
                        globalState={this.props.globalState} />
                }
            </>
        );
    }

    private getCategory(article: ISalesDesignationView): ICategory {
        if (this.props.category != null) {
            return this.props.category;
        } else {
            return {
                seoSlug: article.seoSlug,
                navigationKey: article.navigationKey,
                hideBreadcrumbAndFilterBar: false,
                hideStickyHeader: false,
                productFinderServicesUpAndRunning: false,
                categoryName: this.props.l10n.itemsSearch
            }
        }
    }
}
