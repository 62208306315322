import { ISelectedFilter } from './selectedFilters.d';
import { IFilterModel } from './filterModel.d';
import { IUrlController, UrlController } from './UrlController';
import { ArticleListController } from './ArticleListController';
import { IKioskInfo } from '../categorypage/categorypage.d';
import { IFilteringResult } from '../articleTileGrid/article-tile/article-tile.d';
import { SelectedFiltersState } from './SelectedFiltersState';
import { ViewType } from '../../Common/enums';

export type Handler<E> = (event: E) => void;

export interface IGlobalStateChangedEvent {
    originalTrigger: string;
    selectedFilters: ISelectedFilter[];
    filterModel: IFilterModel;
    lastAddedFilter: ISelectedFilter;
    lastAddedFilterSource: string;
    retailStoreFilterMode: string;
}

export class GlobalState {
    private _selectedFilters: ISelectedFilter[] = [];
    private _kiosk: IKioskInfo = null;
    private _urlController: IUrlController;
    private _articleListController: ArticleListController;
    private _selectedFiltersState: SelectedFiltersState;
    private _lastAddedFilter: ISelectedFilter;
    private _viewType: ViewType;
    private _magicLink: string;
    public onNewArticlesLoaded: (articles)=>void

    get urlController(): IUrlController {
        return this._urlController;
    }
    get lastAddedFilter() {
        return this._lastAddedFilter;
    }
    private _lastAddedFilterSource: string;
    get lastAddedFilterSource() {
        return this._lastAddedFilterSource;
    }

    get selectedFilters() {
        return this._selectedFilters;
    }

    private _filterModel: IFilterModel;
    get filterModel() {
        return this._filterModel;
    }

    constructor(filterModel: IFilterModel,
        selectedFilters: ISelectedFilter[],
        seoSlug: string,
        searchTerm: string,
        kiosk: IKioskInfo,
        magicLink: string) {

        this._selectedFilters = [...selectedFilters];
        this._filterModel = { ...filterModel };
        this._viewType = searchTerm ? ViewType.Search : ViewType.Category;
        this._urlController = new UrlController(seoSlug, this._viewType, magicLink);
        this._kiosk = kiosk;
        this.onNewArticlesLoaded = this._onNewArticlesLoaded.bind(this);
        this._magicLink = magicLink;
    }

    public get getMagicLink(): string {
        return this._magicLink;
    }

    set articleListController(articleListController: ArticleListController) {
        this._articleListController = articleListController;
    }

    set selectedFiltersState(selectedFiltersState: SelectedFiltersState) {
        this._selectedFiltersState = selectedFiltersState;
    }

    get kioskInfo() {
        return this._kiosk;
    }

    private _onNewArticlesLoaded(articles: IFilteringResult) {
        for (const h of this.articlesChangedHandlers)
            h(articles);
    }


    public removeAllFilters() {
        this.updateState(
            [], this.filterModel, 'removeAllFilters', null, null, this._kiosk, this._viewType
        );
        // ESPP-9504: selectedFilterState need here to trigger ajax update for matchingArticleCount
        this._selectedFiltersState.globalRemoveAllFilters();
    }

    public updateState(
        selectedFilters: ISelectedFilter[],
        filterModel: IFilterModel,
        originalTrigger: string,
        lastAddedFilter: ISelectedFilter,
        lastAddedFilterSource: string,
        kiosk: IKioskInfo,
        viewType: ViewType) {
        // use clone instead of copy to make sure reference update
        this._selectedFilters = [...selectedFilters];
        this._filterModel = { ...filterModel };
        this._lastAddedFilter = lastAddedFilter;
        this._lastAddedFilterSource = lastAddedFilterSource;
        this._kiosk = kiosk;
        this._viewType = viewType;
        const event: IGlobalStateChangedEvent = {
            filterModel: this._filterModel,
            retailStoreFilterMode: kiosk?.retailStoreFilterMode,
            lastAddedFilter,
            lastAddedFilterSource,
            originalTrigger,
            selectedFilters: this._selectedFilters,
        };

        this._urlController.updateUrlForFiltering(
            this._selectedFilters,
            this._lastAddedFilter,
            this._lastAddedFilterSource);

        const shouldForceScrollUp: boolean = originalTrigger === 'fas-side-content';

        this.updateArticleList(shouldForceScrollUp);

        for (const h of this.filterModelChangeHandlers)
            h(event);
    }

    public updateArticleList(shouldForceScroll: boolean) {
        this._articleListController.updateArticleList(
            this._lastAddedFilter, this._lastAddedFilterSource,
            shouldForceScroll, this._kiosk, this._viewType);
    }

    private filterModelChangeHandlers: Array<Handler<IGlobalStateChangedEvent>> = [];
    private articlesChangedHandlers: Array<Handler<IFilteringResult>> = [];
    public registerOnStateChanged(handler: Handler<IGlobalStateChangedEvent>) {
        this.filterModelChangeHandlers.push(handler);
    }
    public registerOnArticlesChanged(handler: Handler<IFilteringResult>) {
        this.articlesChangedHandlers.push(handler);
    }

    public Spinner = new GlobalSpinnerState();
}

export class GlobalSpinnerState {

    private _spinnerStack = 0;
    private spinnerStateChangedHandlers: { [id: string]: Handler<boolean> } = {};
    public registerSpinnerChanged(id: string, handler: Handler<boolean>) {
        this.spinnerStateChangedHandlers[id] = handler;
    }
    public unregisterSpinnerChanged(id: string) {
        delete this.spinnerStateChangedHandlers[id];
    }
    private sendSpinnerStateEvent(newState: boolean) {
        const entries = Object.entries(this.spinnerStateChangedHandlers);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        entries.forEach(([_, handler]) => {
            handler(newState);
        })
    }

    public stopSpinner() {
        this._spinnerStack--;
        if (this._spinnerStack > 0) return;

        const now = new Date().getTime();
        const spinnerShownDuration = now - this._spinnerStartActualTime;
        if (spinnerShownDuration < GlobalSpinnerState.SpinnerMinimumTime) {
            setTimeout(() => {
                this.sendSpinnerStateEvent(false);
            }, GlobalSpinnerState.SpinnerMinimumTime - spinnerShownDuration);
        } else {
            this.sendSpinnerStateEvent(false);
        }
    }
    private _spinnerStartActualTime: number;
    static readonly SpinnerMinimumTime: number = 1000;
    public startSpinner() {
        this._spinnerStack++;
        setTimeout(() => {
            if (this._spinnerStack > 0) {
                this.sendSpinnerStateEvent(true);
                this._spinnerStartActualTime = new Date().getTime();
            }
        }, 200);
    }
}
