export interface ISortComponent {
    isDisabled: boolean;
    titleMlt: string;
    sortingProperties: ISortProperty[];
    viewType: string;
    updateSort(sort: ISortProperty);
}

export enum SortType {
    Popularity = 1,
    NewestFirst,
    PriceDescending,
    PriceAscending,
    GlovesRecommendation,
}

export interface ISortProperty {
    title?: string;
    selected: boolean;
    sortType: SortType;
    urlValue: string;
}

export interface ISortComponentState {
    isOpen: boolean;
    headerTitle: string;
    selectedValue: ISortProperty;
}
export interface ISortSideComponentState{
    componentMounted: boolean;
}
