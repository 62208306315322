import { ISalesDesignationView } from '../components/articleTileGrid/article-tile/article-tile.d';

export function decodeHTML(value: string): string {
    return value.replace(/&#(\d+);/g, (_match, dec) => {
        return String.fromCharCode(dec);
    });
}

export function ssrSafeDecodeHTML(value: string): string {
    if (typeof window !== 'undefined')
        return decodeHTML(value);
    else
        return value;
}

export function isMobileShop_DontUseFromReact(): boolean {
    if (typeof document !== 'undefined')
        return document.getElementsByTagName('html')[0].classList.contains('mobileshop');
    else
        return false;
}

export function getPortalSelector(isGlobal: boolean): string {
    if (isGlobal)
        return 'fas_global';
    else
        return '';
}

export function throttle(callback, delay: number) {
    let timeoutHandler = null;
    return () => {
        if (timeoutHandler === null)
            timeoutHandler = setTimeout(() => {
                callback();
                timeoutHandler = null;
            }, delay);
    };
}
export function onScroll(
    pageEndOffset: number, 
    salesDesignationViews: ISalesDesignationView[],
    scrollSetter: (pos) => void,
    infiniteScrollUp: Function
): void {
    requestAnimationFrame(() => {
        const newScrollPosition = calculateScrollDistance(pageEndOffset, salesDesignationViews);
        scrollSetter(newScrollPosition);
        const lastVisibleElement = getLastVisibleTile(salesDesignationViews);
        if((!lastVisibleElement && isInUpperHalf()) 
            || getIndexOfLastVisibleElement(pageEndOffset, salesDesignationViews) < 8){
                infiniteScrollUp();
        }
    });
}

export function calculateScrollDistance(pageEndOffset: number, salesDesignationViews: ISalesDesignationView[]): number {
    if(!hasElementRefs(salesDesignationViews))
        return 0;
    if(salesDesignationViews.length <= 4)
        return 100;
    const notYetLoadedArticles = pageEndOffset - salesDesignationViews.length;
    const indexOfLastVisibleElement = getIndexOfLastVisibleElement(pageEndOffset, salesDesignationViews);
    const seenArticles = notYetLoadedArticles + indexOfLastVisibleElement + 1;
    const scrollPosition = Math.floor((seenArticles) / pageEndOffset * 100);

    return Math.min(scrollPosition, 100);
}

export function getIndexOfLastVisibleElement(pageEndOffset: number, salesDesignationViews: ISalesDesignationView[]): number {
    if(!hasElementRefs(salesDesignationViews))
        return 0;
    const lastVisibleElement = getLastVisibleTile(salesDesignationViews);
    let indexOfLastVisibleElement = 0;
    if (lastVisibleElement)
        indexOfLastVisibleElement = salesDesignationViews.findIndex(x => x.tileId == lastVisibleElement.id);
    else
        indexOfLastVisibleElement = isInUpperHalf() ? 0 : pageEndOffset;
    return indexOfLastVisibleElement;
}

function hasElementRefs(salesDesignationViews: ISalesDesignationView[]): boolean {
    if(!salesDesignationViews || salesDesignationViews.length == 0) return false;
    for (let index = 0; index < salesDesignationViews.length; index++) {
        const element = salesDesignationViews[index];
        if(!element.elementRef || !element.elementRef.current)
            return false;
    }
    return true;
}

export function isInUpperHalf(): boolean {
    const screenHeight = document.body.offsetHeight;
    const currentY = window.scrollY;
    return currentY < (screenHeight / 2);
}

export function getLastVisibleTile(salesDesignationViews: ISalesDesignationView[]): Element {
    if(!hasElementRefs(salesDesignationViews))
        return null;
    const elements = salesDesignationViews.map(x=>x.elementRef).filter(x=>x.current != null);
    let lastVisibleElement: Element = null;

    for (let i = elements.length - 1; i >= 0; i--) {
        const element = elements[i].current;
        const rect = element.getBoundingClientRect();

        if (
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) 
        ) {
            lastVisibleElement = element;
            break;
        }
    }
    return lastVisibleElement;
}

export function isUrlWithPageQuery(url: string): boolean {
    const urlObject = new URL(url);
    return urlObject.searchParams.has('page');
}

export function originalPageUrl(url: string): string {
    const urlObject = new URL(url);
    urlObject.searchParams.delete('page');
    return urlObject.toString();
}

export function isScrolledIntoView(el: Element) {
    const rect = el.getBoundingClientRect();
    const elemTop = rect.top;
    const elemBottom = rect.bottom;

    // Only completely visible elements return true:
    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // Partially visible elements return true:
    //isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
}

export function isPageBackgroundBlack(): boolean {
    // page body background validation
    if (typeof document === 'undefined')
        return false;

    const color = window.getComputedStyle(document.body, null).getPropertyValue('background-color');

    return color === 'rgb(0, 0, 0)' ? true : false;
}
