export class IEsAppService {
    public navigationWheel: NavigationWheel;
    constructor() {
        this.navigationWheel = new NavigationWheel();
    }
}

class NavigationWheel {
    private visible: boolean;
    constructor() {
        this.visible = true;
    }

    public isVisible() {
        return this.visible;
    }

    public show() {
        this.visible = true;
        document.dispatchEvent(new CustomEvent('esapp.navigationwheel', {
            detail: {
                visible: true,
            },
        }));
    }
    public hide() {
        this.visible = false;
        document.dispatchEvent(new CustomEvent('esapp.navigationwheel', {
            detail: {
                visible: false,
            },
        }));
    }
}
