import React from 'react';

const Info = () => (
    <svg data-name='mpc-info' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13.0005 13.0005'>
        <path
            d='M6.5,13A6.5,6.5,0,1,1,13,6.5,
               6.5076,6.5076,0,0,1,6.5,13Zm0-12A5.5,
               5.5,0,1,0,12,6.5,5.5061,5.5061,0,0,0,6.5,1Z'
            transform='translate(0.0002 0.0002)'
        />
        <path
            d='M6.5073,4.105A.6293.6293,0,0,1,6.06,3.9312a.6457.6457,
               0,0,1-.1856-.4932.6389.6389,0,0,1,.19-.4756.6146.6146,
               0,0,1,.4424-.1865.6124.6124,0,0,1,.6177.6621.65.65,
               0,0,1-.1831.49A.6025.6025,0,0,1,6.5073,4.105ZM7.125,
               5.5093V9.4365a.9283.9283,0,0,1-.1782.6172.57.57,
               0,0,1-.4531.209.5478.5478,0,0,1-.4473-.2139.9581.9581,
               0,0,1-.1719-.6123V5.5483a.922.922,0,0,1,.1719-.6064.5561.5561,
               0,0,1,.4473-.2041.5746.5746,0,0,1,.4531.2041A.8347.8347,
               0,0,1,7.125,5.5093Z'
            transform='translate(0.0002 0.0002)'
        />
    </svg>
);

export default Info;
