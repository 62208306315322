import * as React from 'react';
import classNames from 'classnames';
import styles from './size-value.scss';
import f_styles from '../filterValue/filter-value.scss';
import { IFilterValueProps } from '../filterValue/filter-value.d';

export interface ISizeFilterProps extends IFilterValueProps {
    isSpecialLayout: boolean;
}

export default class SizeValue extends React.Component<ISizeFilterProps> {
    constructor(props) {
        super(props);
        this.state = {};
        this.onValueClicked = this.onValueClicked.bind(this);
    }

    public render() {
        return (
            <div className={classNames(styles.size_value,
                f_styles.filter_value,
                { [f_styles.selected_value]: this.props.isSelected },
                { [styles.special_layout]: this.props.isSpecialLayout },
                { [styles.disabled_value]: this.props.filterPropertyValue.articlesAmount === 0 })}
                data-testid='size_value'
                onClick={this.onValueClicked}>
                <div className={styles.size_title}>
                    {this.props.filterPropertyValue.title}
                </div>
            </div>
        );
    }

    private onValueClicked() {
        if (this.props.isSelected)
            this.props.removeSelectedFilter(this.props.filter);
        else
            this.props.addSelectedFilter(this.props.dimension, this.props.filterPropertyValue);
    }
}
