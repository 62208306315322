import React from 'react';

const SpinningArrow2021 = () => (

    <svg
        xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="10 10 20 20" className="arrow-2021">
        <path d="M28.907,18.68605a8.92205,8.92205,0,0,0-16.67236-2.36786l-.7085-1.89532a.89969.89969,0,1,
        0-1.68554.62988l1.583,4.2373a448.252,448.252,0,0,1,.14161.22944,1.13853,1.13853,0,0,0,
        .259.22479c.0249.01434.04272.03766.06909.04968a.86184.86184,0,0,0,.11963.026.82977.82977,0,0,0,
        .09228.037c.02515.00464.0481-.00366.073-.0011.03.003.05786.01917.08814.01917a.90986.90986,0,0,0,
        .12451-.02234.86726.86726,0,0,0,
        .12329-.02216c.02222-.00635.04565-.00409.06763-.01215l4.2373-1.58349a.90032.90032,0,0,
        0-.63086-1.68652l-2.50073.93481a7.10461,7.10461,0,1,1,.37085,6.6145.89987.89987,0,0,0-1.541.92969,8.95059,
        8.95059,0,0,0,7.65039,4.3125A8.91789,8.91789,0,0,0,28.907,18.68605Z" />
    </svg>
);

export default SpinningArrow2021;
