export function subscribe(eventName: string, listener: EventListenerOrEventListenerObject): void {
    if (typeof document === 'undefined')
        return;

    document.addEventListener(eventName, listener);
}

export function unsubscribe(eventName: string, listener: EventListenerOrEventListenerObject): void {
    if (typeof document === 'undefined')
        return;

    document.removeEventListener(eventName, listener);
}

export function publish(eventName: string, data): void {
    if (typeof document === 'undefined')
        return;

    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}
