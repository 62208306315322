import { IServiceSelectedFilter, ISelectedFilter } from '../globalState/selectedFilters.d';
import { ArticleListController } from './ArticleListController';
import { convertFromReactToServiceWorld } from './ServiceSelectedFilterConverter';
import * as helper from '../../Common/html-helper';
import { ISortProperty } from '../sort-side-content/sort-side-content.d';

export interface IUrlController {
    updateUrlForPaging(pageNo: number, tileId: string): void;
    updateUrlForFiltering(
        selectedFilters: ISelectedFilter[],
        lastAddedFilter: ISelectedFilter,
        lastAddedFilterSource: string): void;
}
export class UrlController implements IUrlController {
    private seoSlug: string;
    private viewType: string;

    constructor(seoSlug: string,
                viewType: string,
                private magicLink: string) {
        this.seoSlug = seoSlug;
        this.viewType = viewType;
    }

    public updateUrlForPaging(pageNo: number, tileId: string): void
    {
        if (typeof window === 'undefined')
            return;
        
        const url = new URL(window.location.href);
        if(pageNo <= 1) 
            url.searchParams.delete('page'); 
        else
            url.searchParams.set('page', pageNo.toString());

        if(tileId)
            url.hash = tileId;
        history.replaceState(null, '', url);
    }

    public updateUrlForFiltering(
                selectedFilters: ISelectedFilter[],
                lastAddedFilter: ISelectedFilter,
                lastAddedFilterSource: string)
    {
        if (typeof window === 'undefined')
            return;

        const url = new URL(window.location.href);
        url.searchParams.delete('page');

        let urlNoFilter = this.magicLink;

        const serviceSelectedFilters = convertFromReactToServiceWorld(selectedFilters).sort((a, b) => {
            return a.dimensionSlug.localeCompare(b.dimensionSlug);
        });

        if (this.viewType !== 'Search') {
            if (selectedFilters.length > 0)
                urlNoFilter += 'f/';
            urlNoFilter += this.seoSlug + '/';
            urlNoFilter += this.toUrl(serviceSelectedFilters);
            urlNoFilter += url.search;
        } else {
            if (selectedFilters.length > 0) {
                const queryFilters = this.toUrl(serviceSelectedFilters).slice(0, -1);
                url.searchParams.set('filterParams', queryFilters);
            } else
                url.searchParams.delete('filterParams');

            url.searchParams.sort();

            urlNoFilter = url.toString();
            // ESPP-4200
            urlNoFilter = urlNoFilter.replace(/%3A/g, ':');
            urlNoFilter = urlNoFilter.replace(/%2C/g, ',');
        }

        if (helper.isMobileShop_DontUseFromReact())
            urlNoFilter = ArticleListController.AddLastAddedForTracing(
                urlNoFilter,
                lastAddedFilter,
                lastAddedFilterSource);

        history.replaceState(selectedFilters, '', urlNoFilter);
    }

    private toUrl(selectedFilters: IServiceSelectedFilter[]) {
        let joined = selectedFilters.map((x) => x.dimensionSlug + ':' + x.valueSlugs.sort().join(',')).join('/');
        if (selectedFilters.length > 0)
            joined += '/';

        return joined;
    }

    public updateSortUrl(sortUrl: string) {
        if (typeof window === 'undefined')
            return;

        const query = new URLSearchParams(window.location.search);
        query.delete('page');

        if (!sortUrl)
            query.delete('sort');
        else
        if (!query.get('sort'))
            query.append('sort', sortUrl);
        else
            query.set('sort', sortUrl);

        query.sort();
        let newPathQuery = window.location.pathname;
        if (query.toString().length > 0)
            newPathQuery += '?' + query.toString();

        history.pushState(null, '', newPathQuery);
    }

    public updateGloveSortUrl(sortPropertyList: ISortProperty[], gloveSortUrlValue: string) {
        const query: URLSearchParams = new URLSearchParams(window.location.search);
        const sortValue: string = query.get('sort');
        const isNormalSort = this.isNormalSortUrlValue(sortValue, sortPropertyList);
        if (!isNormalSort || gloveSortUrlValue.length > 0)
            this.updateSortUrl(gloveSortUrlValue);
    }

    private isNormalSortUrlValue(urlValue: string, sortPropertyList: ISortProperty[]): boolean {
        const sortValue = sortPropertyList.find((sortProperty) => sortProperty.urlValue === urlValue);
        return typeof sortValue !== 'undefined';
    }
}
