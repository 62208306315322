import React, {PropsWithChildren, RefObject, useRef} from 'react';
import styles from './stickyHeader.scss';
import classnames from 'classnames';
import * as helper from '../../Common/html-helper';
import { isIOS } from 'react-device-detect';
import { ScrollLock } from '../../../Helper/scrollLock';
import NewsLetterPromotion from '../newsLetterPromotion/newsLetterPromotion';

const breakpoint1024 = 1024;

const placeholderRef = {
    current: {
        clientHeight: 0,
        getBoundingClientRect() {
            return { top: 0 };
        },
    },
};

function StickyHeader({ children, isBreadcrumbOnly, isEsApp, isGlobal, gridRef, visibleCallBack, 
    renderNLP, nlpIsVisibleByParentRules }:
    PropsWithChildren<{
        isBreadcrumbOnly: boolean, isEsApp: boolean, isGlobal: boolean,
        gridRef: RefObject<HTMLDivElement>, visibleCallBack: (visible: boolean) => void,
        renderNLP: boolean, nlpIsVisibleByParentRules: boolean
    }>) {
    const scrollLock = ScrollLock.instance;
    const [contentVisible, setContentVisible] = React.useState(false);
    const [newsLetterVisible, setNewsLetterVisible] = React.useState(false);
    const nlpHoverInProgressRef = useRef(false);

    const anchorRef = React.useRef<HTMLDivElement>();
    let lastKnownScrollPosition = 0;
    let newsLetterPromotionTimer: ReturnType<typeof setTimeout>;

    const hideNewsLetterPromotion = ()=> {
        // avoid new timer during hover and scroll:
        if(nlpHoverInProgressRef.current === false) {
            newsLetterPromotionTimer = setTimeout(() => {
                // only if we are not scrolling but hovering on nlp:
                if(nlpHoverInProgressRef.current === false)
                    setNewsLetterVisible(false);
            }, 2000);
        }
    };

    const handleScrollOrResize = React.useCallback(() => {
        if (scrollLock.getLockState) {
            setContentVisible(false);
            visibleCallBack(false);
            return;
        }

        const scrollingUp = lastKnownScrollPosition > window.scrollY;
        const isDesktop = window.innerWidth > breakpoint1024;
        lastKnownScrollPosition = window.scrollY;

        const outerRef = anchorRef?.current ? anchorRef : placeholderRef;
        const outerRefTop = outerRef.current?.getBoundingClientRect().top ?
            outerRef.current.getBoundingClientRect().top : 0;

        const articleGridBottom = gridRef.current?.getBoundingClientRect().bottom || 0;
        const visible = (scrollingUp || isDesktop) && outerRefTop <= 50 && articleGridBottom >= 50;
        setContentVisible(visible);
        visibleCallBack(visible);

        // sticky header is visible, but some time nlp has to be hidden:
        if (articleGridBottom <= 750) {
            setNewsLetterVisible(false);
        } else {
            if (renderNLP) {
                clearTimeout(newsLetterPromotionTimer);
                setNewsLetterVisible(true);

                hideNewsLetterPromotion();
            }
        }

    }, []);

    const throttledHandleScrollOrResize = helper.throttle(handleScrollOrResize, 300);

    React.useEffect(() => {
        window.addEventListener('scroll', throttledHandleScrollOrResize);

        if (!isIOS) {
            window.addEventListener('resize', throttledHandleScrollOrResize);
        }

        return () => {
            window.removeEventListener('scroll', () => {
                throttledHandleScrollOrResize;
            });

            if (!isIOS) {
                window.removeEventListener('resize', throttledHandleScrollOrResize);
            }
        };
    }, []);

    const handleHoverOnNewsLetterFloater = () => {
        nlpHoverInProgressRef.current = true;
    };

    const handleEndHoverOnNewsLetterFloater = () => {
        nlpHoverInProgressRef.current = false;
        hideNewsLetterPromotion();
    }

    return (
        <>
            <div ref={anchorRef as React.Ref<HTMLDivElement>} />
            <div
                className={classnames(styles.sticky_header,
                    { [styles.is_breadcrumb_only]: isBreadcrumbOnly })}
                style={{
                    opacity: contentVisible ? 1 : 0,
                    pointerEvents: contentVisible ? 'initial' : 'none',
                    top: isEsApp ? 0 : isGlobal ? 82 : 50
                }}
            >
                <div className={styles.content}>
                    {children}
                </div>
                {renderNLP &&
                    <div data-testid="newsLetterPromotion"
                         onPointerOver={handleHoverOnNewsLetterFloater}
                         onMouseEnter={handleHoverOnNewsLetterFloater}
                         onMouseLeave={handleEndHoverOnNewsLetterFloater}>
                        <NewsLetterPromotion isVisible={nlpIsVisibleByParentRules && newsLetterVisible}/>
                    </div>
                }
            </div>
        </>


    );
}

export default StickyHeader;
