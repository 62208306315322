import React from 'react';
/* tslint:disable */
const IconColors = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="11" viewBox="0 0 13 11">
        <g transform="translate(1.001 0.689)">
            <path data-name="Pfad 1395" d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z"
                  transform="translate(6.999 5.311)" fill="#1999d8"/>
            <path data-name="Pfad 1394" d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z"
                  transform="translate(-1.001 5.311)" fill="#e83a39"/>
            <path data-name="Pfad 1393" d="M2.5,0A2.5,2.5,0,1,1,0,2.5,2.5,2.5,0,0,1,2.5,0Z"
                  transform="translate(2.999 -0.689)" fill="#8ec534"/>
        </g>
    </svg>
);

export default IconColors;
