import { ISalesDesignationView } from '../components/articleTileGrid/article-tile/article-tile.d';

export default class TouchPointFactory {
    private static _instance: TouchPointFactory;

    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public createTouchPointsBatch(newArticles: ISalesDesignationView[]): void {
        if(!newArticles || newArticles.length <= 0)
            return;

        const savKeyArray: {savKey: string; seoSlug: string; displayedInCategoryNavKey: string;} [] = [];
        newArticles.forEach((art) => {
            const defaults =
            art.salesArticleVariantColors.filter(it => it.color.code === art.defaultColorCode);

            if(defaults.length >= 1)
                savKeyArray.push({savKey: defaults[0].salesArticleVariantKey, 
                displayedInCategoryNavKey: art.navigationKey, seoSlug: art.seoSlug});
        });

        if (window.shell && savKeyArray.length > 0)
            window.shell.publishTo('ManualProductComparison.CreateTouchPointsBatch', savKeyArray);
    }
}
