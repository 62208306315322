import React from 'react';
import styles from './noResult.scss';
import * as helper from '../../Common/html-helper';
import classNames from 'classnames';
import FilterIcn2021 from '../../Assets/svg/filter_2021';
import {IL10N} from '../../Common/l10n-keys';
import { GlobalState } from '../globalState/globalState';
import { SortContext } from '../globalState/sortContextProvider';
import { ISortContext } from '../globalState/sortContextProvider.d';
import { ViewType } from '../../Common/enums';
import { publish } from '../../Common/customEventHelper';


function NoResult({ l10n, globalState }:
    { l10n: IL10N; viewType: ViewType; isMobile: boolean; globalState: GlobalState }) {
    const sortContext = React.useContext(SortContext) as ISortContext;

    return (
        <div className={styles.no_filter_result}>
            <div dangerouslySetInnerHTML={{__html: l10n.noFilterResult}}/>
            <div className={styles.buttons}>
                <div
                    className={styles.button}
                    data-testid='noFilterResult_button'
                    onClick={() => {
                        sortContext.removeAllGloveSortValues();
                        globalState.removeAllFilters();
                    }}>{helper.decodeHTML(l10n.clearAllFilters)}</div>
                <div
                    className={classNames(styles.button, styles.edit_filter_btn)}
                    data-testid='noFilterResult_edit_button'
                    onClick={() => {
                        publish('ESPP.MainSidePanel.ShouldOpen', 'Filter');
                    }}>
                    <FilterIcn2021/>
                    {helper.decodeHTML(l10n.editFilter)}
                </div>
            </div>
        </div>
    );
}

export default NoResult;
