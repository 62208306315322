import { IFilterModel } from './filterModel.d';
import { FilterAjaxUpdateController } from './FilterAjaxUpdateController';
import { SelectedFiltersState } from './SelectedFiltersState';
import { ISelectedFilter } from './selectedFilters.d';
import { IGlobalStateChangedEvent, Handler, GlobalState } from './globalState';
import { IKioskInfo } from '../categorypage/categorypage.d';
import { ViewType } from '../../Common/enums';

export interface IFilterModelChangedEvent {
    filterModel: IFilterModel;
}

export interface IErrorWhileUpdatingAvailableFilterEvent {
    error: Error;
}

export interface ICategoryData {
    navigationKey: string;
    categoryPath: string;
    seoSlug: string;
}

export class AvailableFiltersState {
    public selectedFilterState: SelectedFiltersState;

    private filterModel: IFilterModel;
    private handlers: Array<Handler<IFilterModelChangedEvent>> = [];
    private errorHandlers: Array<Handler<IErrorWhileUpdatingAvailableFilterEvent>> = [];
    private ajaxController: FilterAjaxUpdateController;
    private readonly triggerName: string;
    private viewType: ViewType;

    constructor(
        private globalState: GlobalState,
        categoryData: ICategoryData,
        triggerName: string,
        initFilterModel: IFilterModel,
        initSelectedFilters: ISelectedFilter[],
        searchTerm: string,
        kiosk: IKioskInfo,
    ) {
        this.selectedFilterState = new SelectedFiltersState(initSelectedFilters);
        this.ajaxController = new FilterAjaxUpdateController(this.globalState, categoryData.navigationKey,
            this, this.selectedFilterState, searchTerm, kiosk);
        this.triggerName = triggerName;
        this.filterModel = initFilterModel;
        this.viewType = searchTerm ? ViewType.Search : ViewType.Category;
        this.globalState.registerOnStateChanged(this.onGlobalStateChanged.bind(this));
    }

    public updateGlobalState() {
        this.globalState.updateState(
            this.selectedFilterState.selectedFilters,
            this.filterModel,
            this.triggerName,
            this.selectedFilterState.lastAddedFilter,
            this.triggerName, 
            this.ajaxController.kiosk, 
            this.viewType);
    }

    public restoreToGlobalState() {
        const globalStateFilterModel = { ...this.globalState.filterModel };
        this.onNewFilterModel(globalStateFilterModel);
        const globalStateSelectedFilters = [...this.globalState.selectedFilters];
        const kioskInfo = {...this.globalState.kioskInfo};
        this.selectedFilterState.onGlobalStateChanged(
            globalStateSelectedFilters, 
            kioskInfo ? kioskInfo.retailStoreFilterMode : null);
    }

    public getFilterModel(): IFilterModel {
        return this.filterModel;
    }

    public registerOnStateChanged(handler: Handler<IFilterModelChangedEvent>) {
        this.handlers.push(handler);
    }

    public registerErrorHandler(handler: Handler<IErrorWhileUpdatingAvailableFilterEvent>) {
        this.errorHandlers.push(handler);
    }

    public onNewFilterModel(filterModel: IFilterModel) {
        this.filterModel = filterModel;
        this.selectedFilterState.updateSelectedStateWithNewFitlerModel(filterModel);
        this.stateChanged({filterModel} as IFilterModelChangedEvent);
    }

    public onErrorWhileUpdating(error: Error) {
        for (const h of this.errorHandlers)
            h({error} as IErrorWhileUpdatingAvailableFilterEvent);
    }

    private stateChanged(event: IFilterModelChangedEvent) {
        for (const h of this.handlers)
            h(event);
    }

    private onGlobalStateChanged(event: IGlobalStateChangedEvent) {
        if (event.originalTrigger !== this.triggerName) {
            this.onNewFilterModel({ ...event.filterModel });
            this.selectedFilterState.onGlobalStateChanged(
                event.selectedFilters,
                event.retailStoreFilterMode
            );
        }
        if (this.ajaxController.kiosk)
            this.ajaxController.kiosk.retailStoreFilterMode = event.retailStoreFilterMode;
    }
}
