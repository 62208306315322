import * as React from 'react';
import styles from './sidePanel.scss';
import { ContentType } from './contentType';
import Header from '../header/header';
import Content from '../content/content';
import { IFasBarData } from '../../fas-bar/fas-bar.d';
import classNames from 'classnames';
import { GlobalState } from '../../globalState/globalState';
import {IL10N} from '../../../Common/l10n-keys';

export default class SidePanel extends React.Component<{onClose, onReset, 
    filterContentViewModel: IFasBarData, currentTab: ContentType, 
    onTabChange, isShow, shouldShowProductFinder, productFinderServicesUpAndRunning, globalState: GlobalState,
    l10n: IL10N, productFinderKey: string
}> {
    constructor(props) {
        super(props);
        this.state = { currentState: ContentType.FilterContent };
    }

    public handleContentChange = (prop) => {
        this.props.onTabChange(prop.target.dataset.content as ContentType);
    }

    public render() {
        return (
            <>
                <div className={styles.msp_mainSidePanelOverlay}
                    data-testid='msp_mainSidePanelOverlay'
                    onClick={this.props.onClose} />
                <div className={classNames(styles.msp_sidePanel, 'msp-sidePanel')}>
                    <div className={styles.msp_content_wrapper}>
                        <Header onStateChange={this.handleContentChange}
                            onClose={this.props.onClose} onReset={this.props.onReset}
                            localizations={this.props.l10n}
                            selectedTab={this.props.currentTab} isShow={this.props.isShow}
                            shouldShowProductFinder={this.props.shouldShowProductFinder}
                            productFinderKey={this.props.productFinderKey}
                            isSortContent={this.props.currentTab == ContentType.SortContent}
                            shouldShowFilters={this.props.filterContentViewModel}/>
                        <Content initialState={this.props.currentTab}
                            shouldShowProductFinder={this.props.shouldShowProductFinder}
                            productFinderServicesUpAndRunning={this.props.productFinderServicesUpAndRunning}
                            filterContentViewModel={this.props.filterContentViewModel}
                            globalState={this.props.globalState}
                            productFinderKey={this.props.productFinderKey}
                            localizations={this.props.l10n}
                         />
                    </div>
                </div>
            </>

        );
    }
}
