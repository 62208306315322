import * as React from 'react';
import classNames from 'classnames';
import styles from './glovesRecommendation-sort.scss';
import { IGlovesRecommendationProps } from './glovesRecommendation-sort.d';
import Rate from './rate/rate';
import * as helper from '../../../Common/html-helper';
import ReactHtmlParser from 'react-html-parser';
import { SortContext } from '../../globalState/sortContextProvider';

export default class GlovesRecommendationSort extends React.Component<IGlovesRecommendationProps, 
    { mobileTouchStarted: boolean }> {
    static contextType = SortContext;

    constructor(props, context) {
        super(props, context);

        this.state = {
            mobileTouchStarted: false
        };

        this.onTouchStart = this.onTouchStart.bind(this);
        this.onTouchEnd = this.onTouchEnd.bind(this);
    }

    private onTouchStart(): void {
        this.setState({mobileTouchStarted: true});
    }

    private onTouchEnd(): void {
        this.setState({mobileTouchStarted: false});
    }

    public render() {
        return (
            <SortContext.Consumer>
                {(sortContext) => (
                    <div className={classNames(styles.gloves_recommendation,
                        { [styles.gloves_mobile_layout]: this.props.isMobile})}>
                        <div className={styles.glove_filter_text_2}
                             dangerouslySetInnerHTML={{__html: ReactHtmlParser(this.props.gloveFilterText2)}}></div>
                        <div className={styles.gloves_ratings}
                             onTouchStart={this.onTouchStart}
                             onTouchEnd={this.onTouchEnd}>
                            <div className={styles.importance}>
                                <div className={styles.important_less}>
                                    {helper.decodeHTML(this.props.rateLessImportantTitle)}</div>
                                <div className={styles.important_very}>
                                    {helper.decodeHTML(this.props.rateVeryImportantTitle)}</div>
                            </div>
                            {
                                sortContext.glovesSortPropValues.map((gloveFilterSort, index) => {
                                    return (<Rate glovesFilterSort={gloveFilterSort}
                                                  mobileTouchStarted={this.state.mobileTouchStarted}
                                                  isMobile={this.props.isMobile}
                                                  key={index}></Rate>
                                    );
                                })}
                        </div>
                    </div>
                )}
            </SortContext.Consumer>
        );
    }
}
