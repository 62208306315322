import * as React from 'react';
import classNames from 'classnames';
import styles from './spinnerComponent.scss';
import { ISpinnerComponentProps, ISpinnerComponentState } from './spinnerComponent.d';

export default class SpinnerComponent extends React.Component<ISpinnerComponentProps, ISpinnerComponentState> {

    constructor(props: ISpinnerComponentProps) {
        super(props);
        this.state = {showLoadingSpinner: false};
    }
    
    public componentDidMount() {
        this.props.globalState.Spinner.registerSpinnerChanged(this.props.context, this.changeSpinnerState.bind(this));
    }
    public componentWillUnmount() {
        this.props.globalState.Spinner.unregisterSpinnerChanged(this.props.context);
    }

    private changeSpinnerState(isVisible: boolean)  {
        if (this.state.showLoadingSpinner == isVisible) return;
        this.setState({ showLoadingSpinner: isVisible });
    }

    public render() {
        return (
            <div className={classNames(styles.spinner_overlay,
                this.state.showLoadingSpinner ? styles.show : '',
                this.props.isFixedLayout ? styles.fixed : '',
                this.props.overlayBreadcrumb ? styles.overlay : '')}>
                <div className={classNames(styles.loaderContainer,
                    this.props.hideLoadingSpinner ? styles.hidden : '')}>
                    <div className={styles.loader}></div>
                </div>
            </div>
        );
    }
}
