import { ISelectedFilter } from './selectedFilters.d';
import { Handler } from './globalState';
import { IFilterModel } from './filterModel.d';
import { IFilterProperty } from '../fas-bar/filter-property.d';

export enum FilterSelectionChangedType {
    FilterAdded,
    FilterRemoved,
    AllFiltersRemoved,
    GlobalUpdate,
    GlobalFilterRemoved,
    GlobalAllFilterRemoved,
    RetailStoreFilterModeChanged,
}

export interface IFilterSelectionChangedEvent {
    type: FilterSelectionChangedType;
    selectedFilters: ISelectedFilter[];
    changedFilter: ISelectedFilter;
    retailStoreFilterMode?: string;
}

export class SelectedFiltersState {
    private _selectedFilters: ISelectedFilter[] = [];
    get selectedFilters() {
        return this._selectedFilters;
    }
    private _lastAddedFilter: ISelectedFilter;
    get lastAddedFilter() {
        return this._lastAddedFilter;
    }
    private handlers: Array<Handler<IFilterSelectionChangedEvent>> = [];

    constructor(initselectedFilters: ISelectedFilter[]) {
        this._selectedFilters = initselectedFilters;
    }

    public registerOnStateChanged(handler: Handler<IFilterSelectionChangedEvent>) {
        this.handlers.push(handler);
    }

    public onGlobalStateChanged(
        newSelectedFilters: ISelectedFilter[],
        retailStoreFilterMode: string) {
        this._selectedFilters = [...newSelectedFilters];
        this.stateChanged(
            {
                selectedFilters: newSelectedFilters,
                type: FilterSelectionChangedType.GlobalUpdate,
                retailStoreFilterMode: retailStoreFilterMode
            } as IFilterSelectionChangedEvent);
    }

    public removeAllFilters() {
        this._selectedFilters = [];
        this._lastAddedFilter = null;
        this.stateChanged(
            {
                selectedFilters: this._selectedFilters,
                type: FilterSelectionChangedType.AllFiltersRemoved,
            } as IFilterSelectionChangedEvent);
    }

    public globalRemoveAllFilters() {
        this._selectedFilters = [];
        this._lastAddedFilter = null;
        this.stateChanged(
            {
                selectedFilters: this._selectedFilters,
                type: FilterSelectionChangedType.GlobalAllFilterRemoved,
            } as IFilterSelectionChangedEvent);
    }

    public addFilter(filter: ISelectedFilter) {
        // don't push, create new one, for change detection
        if (filter !== null) {
            this._selectedFilters = this._selectedFilters.concat([filter]);
            this._lastAddedFilter = filter;
        }
        this.stateChanged(
            {
                changedFilter: filter,
                selectedFilters: this._selectedFilters,
                type: FilterSelectionChangedType.FilterAdded,
            } as IFilterSelectionChangedEvent);
    }

    public changeKiosk(retailStoreFilterMode: string) {
        this.stateChanged(
            {
                changedFilter: null,
                retailStoreFilterMode,
                selectedFilters: this._selectedFilters,
                type: FilterSelectionChangedType.RetailStoreFilterModeChanged,
            } as IFilterSelectionChangedEvent);
    }

    public removeFilter(filter: ISelectedFilter) {
        // ESPP-6123
        const isRestValueValid = this._selectedFilters.find((sf) => {
            const isSameDimesion = sf.dimension.name === filter.dimension.name;
            const hasArticle = sf.value.articlesAmount > 0;
            const notRemovedFilter = sf.value.id !== filter.value.id;
            return isSameDimesion && hasArticle && notRemovedFilter;
        });

        if (isRestValueValid !== undefined)
            this._selectedFilters.splice(
                this._selectedFilters.findIndex((x) => {
                    const sameDimesion = x.dimension.name === filter.dimension.name;
                    const sameId = x.value.id === filter.value.id;
                    return sameDimesion && sameId;
                })
                , 1);
        else
            this._selectedFilters = this._selectedFilters.filter((x) => {
                return x.dimension.name !== filter.dimension.name;
            });

        this._lastAddedFilter = null;
        this.stateChanged(
            {
                changedFilter: filter,
                selectedFilters: this._selectedFilters,
                type: FilterSelectionChangedType.FilterRemoved,
            } as IFilterSelectionChangedEvent);
    }

    public globalRemoveFilter(filter: ISelectedFilter) {
        // ESPP-6123
        const isRestValueValid = this._selectedFilters.find((sf) => {
            const isSameDimesion = sf.dimension.name === filter.dimension.name;
            const hasArticle = sf.value.articlesAmount > 0;
            const notRemovedFilter = sf.value.id !== filter.value.id;
            return isSameDimesion && hasArticle && notRemovedFilter;
        });

        if (isRestValueValid !== undefined)
            this._selectedFilters.splice(
                this._selectedFilters.findIndex((x) => {
                    const sameDimesion = x.dimension.name === filter.dimension.name;
                    const sameId = x.value.id === filter.value.id;
                    return sameDimesion && sameId;
                })
                , 1);
        else
            this._selectedFilters = this._selectedFilters.filter((x) => {
                return x.dimension.name !== filter.dimension.name;
            });

        this._lastAddedFilter = null;
        this.stateChanged(
            {
                changedFilter: filter,
                selectedFilters: this._selectedFilters,
                type: FilterSelectionChangedType.GlobalFilterRemoved,
            } as IFilterSelectionChangedEvent);
    }

    public anyFiltersSelected(): boolean {
        return this._selectedFilters.length > 0;
    }

    public sortSelectedFilters(): ISelectedFilter[] {
        const dict: { [key: string]: ISelectedFilter[] } = {};
        this._selectedFilters.forEach((filter) => {
            const dimensionName = filter.dimension.name.split(':')[0];
            if (!dict[dimensionName])
                dict[dimensionName] = new Array<ISelectedFilter>();
            dict[dimensionName].push(filter);
        });
        let newOrder: Array<ISelectedFilter> = [];
        for (const key in dict)
            if (dict.hasOwnProperty(key)) {  // eslint-disable-line no-prototype-builtins
                const value = dict[key];
                newOrder = newOrder.concat(value);
            }
        return newOrder;
    }

    public updateSelectedStateWithNewFitlerModel(filterModel: IFilterModel) {
        this._selectedFilters.forEach((selectedFilter, index, arr) => {
            const foundDimension = this.findFilterdimension(filterModel, selectedFilter.dimension.name);
            if (foundDimension) {
                const foundValue = foundDimension.filterPropertyValues.find((filterValue) =>
                    filterValue.id === selectedFilter.value.id);
                if (foundValue)
                    arr[index] = { dimension: foundDimension, value: foundValue } as ISelectedFilter;
                else
                    selectedFilter.value.articlesAmount = 0;
            } else {
                selectedFilter.value.articlesAmount = 0;
            }
        });
    }

    private findFilterdimension(filterModel: IFilterModel, dimesionName: string): IFilterProperty {
        for (const filter of filterModel.filters) {
            const candidate = this.findFilterdimensionSingle(filter, dimesionName);
            if (candidate)
                return candidate;
        }
        return null;
    }

    private findFilterdimensionSingle(filter: IFilterProperty, dimesionName: string): IFilterProperty {
        let result: IFilterProperty;
        if (filter.name === dimesionName)
            result = filter;
        else
            if (filter.subProperties) {
                let tmp: IFilterProperty;
                for (const subFilter of filter.subProperties) {
                    tmp = this.findFilterdimensionSingle(subFilter, dimesionName);
                    if (tmp) {
                        result = tmp;
                        break;
                    }
                }
            }
        return result;
    }

    private stateChanged(event: IFilterSelectionChangedEvent) {
        for (const h of this.handlers)
            h(event);
    }
}
