import React, { Component } from 'react';
import styles from './availableSizesOverlayModal.scss';
import AvailableSizes from './availableSizes';
import { IL10N } from '../../../../Common/l10n-keys';
import { ISdvColor } from '../article-tile.d';
import { ISize } from '../article-tile.d';
import { ISalesArticleVariantsLookup } from '../article-tile.d';
import classNames from 'classnames';
import Cross from '../../../../Assets/svg/cross';
import * as helper from '../../../../Common/html-helper';

export default class AvailableSizesOverlayModal extends Component<{l10n: IL10N,
    salesArticleVariantColors: ISdvColor[],
    toggleAvailableSizes(): void,
    sizes: ISize[]
    salesArticleVariantsLookup: ISalesArticleVariantsLookup[]
    showAvailableSizesOverlay: boolean,
    dcvColorCode: number
    masterArticleNo: number
}, {}> {
    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div data-testid='availableSizesOverlayModal'
                className={classNames(styles.availableSizesOverlayModal,
                    { [styles.show_overlay]: this.props.showAvailableSizesOverlay })}
            >
                <div className={styles.content}>
                    <div className={styles.header}>
                        <span className={styles.title}>
                            {helper.decodeHTML(this.props.l10n.availableSizes)}
                        </span>
                        <span onClick={this.props.toggleAvailableSizes} className={styles.close_btn_icon}>
                            <Cross />
                        </span>
                    </div>
                    <AvailableSizes
                        l10n={this.props.l10n}
                        salesArticleVariantColors={this.props.salesArticleVariantColors}
                        showAvailableSizesMenu={true}
                        dcvColorCode={this.props.dcvColorCode}
                        toggleAvailableSizes={null}
                        sizes={this.props.sizes}
                        salesArticleVariantsLookup={this.props.salesArticleVariantsLookup}
                        masterArticleNo={this.props.masterArticleNo}
                    />
                    <div className={styles.footer} data-testid='footer'>
                        <span data-testid='close_button' className={styles.close_button} 
                        onClick={this.props.toggleAvailableSizes}>{helper.decodeHTML(this.props.l10n.close)}</span>
                    </div>
                </div>
            </div>
        );
    }
}
