import React, { Component } from 'react';
import FragmentPlaceholder from '../../Common/fragment-placeholder/fragment-placeholder';
import styles from './newsLetterPromotion.scss';
import { INewsLetterPromotionProps } from './newsLetterPromotion.d';
import classNames from 'classnames';

const defaultProps: INewsLetterPromotionProps = {
    isVisible: false
};

class NewsLetter extends Component<INewsLetterPromotionProps> {

    static defaultProps = defaultProps;

    public render() {
        const { forwardRef, isVisible } = this.props;

        return (
            <div ref={forwardRef} className={classNames(styles.newsLetterPromotion, {
                [styles.visible]: isVisible
            })}>
                <FragmentPlaceholder src='/newsletterpromotionurlservice/widgets/floater?source=PromoFloaterWeb'
                    fallback-src='' dclass='not-kiosk' ignoreErrors={true} timeout={2} />
            </div>
        );
    }
}
const NewsLetterPromotion = React.forwardRef<HTMLDivElement, INewsLetterPromotionProps>((props, ref) => {
    return <NewsLetter forwardRef={ref} {...props} />
});

export default NewsLetterPromotion;
