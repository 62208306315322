import * as React from 'react';
import classNames from 'classnames';
import styles from './progressBar.scss';
import { IProgressBar } from './progressBar.d';

export default class ProgressBar extends React.Component<IProgressBar> {

    constructor(props: IProgressBar) {
        super(props);
    }

    public render() {
        const value = Math.min(1, this.props.currentValue / this.props.maxValue) * 100;
        return (
            <div className={classNames(styles.progress_bar_frame)}>
                <div className={styles.progress_bar}
                    style={{ width: value.toString() + '%' }}>
                </div>
            </div>
        );
    }
}
