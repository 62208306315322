import React from 'react';

const Cross = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <path d="M21.27274,20.00024,27.136,14.137a.90011.90011,0,0,0-1.27246-1.27343l-5.86377,5.86376L14.136,
        12.86353A.90011.90011,0,0,0,12.86356,14.137l5.86328,5.86328-5.86328,5.86329A.90011.90011,0,1,0,14.136,
        27.137l5.86377-5.86377L25.86356,27.137A.90011.90011,0,0,0,27.136,25.86353Z" />
    </svg>
);

export default Cross;
