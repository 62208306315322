import classNames from 'classnames';
import React, { Component } from 'react';
import { IL10N } from '../../Common/l10n-keys';
import ProgressBar from '../progressBar/progressBar';
import styles from './paging.scss';
import * as helper from '../../Common/html-helper';

export default class Paging extends Component<
    { viewedArticleNumber: number, 
        totalArticleCount: number, 
        waitingForResponse: boolean, 
        onLoadMore,
        l10n: IL10N,
        totalPagesNumber: number,
        currentPageNumber: number,
        showHiddenLinks: boolean,
        url?: string
    }, 
    { mounted: boolean }> {

    constructor(props) {
        super(props);

        this.state =
        {
            mounted: false
        };

        this.handleLoadMoreClick = this.handleLoadMoreClick.bind(this);
    }

    componentDidMount(): void {
        this.setState({mounted: true})
    }

    private handleLoadMoreClick() {
        this.props.onLoadMore();
    }

    private hiddenLink() {
        const params = (new URL(this.props.url));
        const urlStart = params.protocol + '//' + params.host + params.pathname;
        const listItem = [];
        for (let i = 2; i <= this.props.totalPagesNumber; i++) {
            listItem.push(< a href={`${urlStart}?page=${i}`} key={i}></a >);
        }
        return listItem;
    }

    public render() {
        //TODO find a SSR safe way to do this
        let pagingString = this.props.totalArticleCount === 1 ? this.props.l10n.lookedArticle :
            this.props.l10n.lookedArticles;
        pagingString = pagingString.replace('{0}', (this.props.viewedArticleNumber).toString());
        pagingString = pagingString.replace('{1}', this.props.totalArticleCount.toString());
        return (
            <div className={styles.paging}>
                <div className={classNames(styles.paging_text,
                    {[styles.invert_color]: this.state.mounted && 
                    helper.isPageBackgroundBlack()})} suppressHydrationWarning={true}>
                    {pagingString}
                </div>
                {this.props.totalArticleCount > 0 && 
                    <div className={styles.paging_bar}>
                        <ProgressBar currentValue={this.props.viewedArticleNumber}
                                     maxValue={this.props.totalArticleCount} />
                    </div>
                }
                {this.props.currentPageNumber < this.props.totalPagesNumber &&
                    <button className={classNames(styles.paging_button,
                        { [styles.disabled]: this.props.waitingForResponse })}
                        data-testid='paging_button'
                        onClick={this.handleLoadMoreClick}>
                        <span>
                            {helper.decodeHTML(this.props.l10n.viewMoreProducts)}
                        </span>
                    </button>
                }
                {this.props.url && this.props.showHiddenLinks &&
                    <div className={styles.hidden_paging}>
                        {this.hiddenLink()}
                    </div>
                }
            </div>
        );
    }
}
