import { ISelectedFilter, IServiceSelectedFilter } from '../globalState/selectedFilters.d';
import { IFilterModel } from '../globalState/filterModel.d';
import { IFilterProperty } from '../fas-bar/filter-property.d';

export function convertFromServiceToReactWorld(
    fromService: IServiceSelectedFilter[],
    filterModel: IFilterModel,
): ISelectedFilter[] {
    if (!fromService) return [];
    return fromService.map((filterSelected) => {
        if (!filterModel.filters)
            return null;
        let filterProperty = filterModel.filters.find(
            (filter) => filter.slug === filterSelected.dimensionSlug ,
        );
        if (!filterProperty)
            // try to look inside sub filters:
            filterProperty = getSubFilter(filterModel, filterSelected);

        if (!filterProperty)
            return null;

        return filterSelected.valueSlugs.map((valueSelected) => {
            let filterPropertyValue = filterProperty.filterPropertyValues.find(
                (value) => value.slug === valueSelected,
            );

            if (!filterPropertyValue) {
                // go throw sub filters:
                filterProperty.subProperties.forEach((subProperty) => {
                    subProperty.filterPropertyValues.forEach((fpv) => {
                        if (fpv.slug === valueSelected)
                            filterPropertyValue = fpv;
                    });
                },
                );

                if (!filterPropertyValue)
                    return null;
            }

            return {
                dimension: filterProperty,
                value: filterPropertyValue,
            } as ISelectedFilter;
        });
    }).reduce((prev, cur) => prev.concat(cur), [])
        .filter((filter) => filter != null);
}

function getSubFilter(filterModel: IFilterModel, filterSelected: IServiceSelectedFilter): IFilterProperty {
    let result: IFilterProperty = null;
    if (!filterModel.filters)
        return result;

    for (const filter of filterModel.filters) {
        if (filter.subProperties && filter.subProperties.length > 0)
            result = filter.subProperties.find(
                (subFilter) => subFilter.slug === filterSelected.dimensionSlug,
            );

        if (result)
            break;
    }

    return result;
}

export function convertFromReactToServiceWorld(
    fromReact: ISelectedFilter[],
): IServiceSelectedFilter[] {
    return fromReact.reduce(
        (rv: IServiceSelectedFilter[], x: ISelectedFilter) => {
            const serviceSelectedFilter = rv.find((y) => y.dimensionSlug === x.dimension.slug);
            if (serviceSelectedFilter)
                serviceSelectedFilter.valueSlugs.push(x.value.slug);
            else
                rv.push({
                    dimensionSlug: x.dimension.slug,
                    valueSlugs: [x.value.slug],
                } as IServiceSelectedFilter);

            return rv;
        },
        []);
}
