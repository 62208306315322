export class ScrollLock {
    // tslint:disable-next-line: variable-name
    private static _instance: ScrollLock;
    private bodyScrollPosition = 0;
    private isLocked = false;
    private timer = null;
    static get instance() {
        return this._instance || (this._instance = new this());
    }

    public lock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');
        const lockAlreadyActived = document.body.getAttribute('style') ?
            document.body.getAttribute('style').length > 0 : false;
        if ((webContainer || mobileContainer) && !lockAlreadyActived) {
            this.bodyScrollPosition = window.scrollY;
            document.body.setAttribute(
                'style',
                'overflow: hidden !important; position: fixed !important; top: -' +
                this.bodyScrollPosition +
                'px;',
            );
            this.isLocked = true;
        }
    }

    public unlock(): void {
        const webContainer = document.querySelector('.container');
        const mobileContainer = document.querySelector('.page-container');

        if (webContainer || mobileContainer) {
            document.body.removeAttribute('style');
            window.scrollTo(0, window.scrollY > 0 ? window.scrollY : this.bodyScrollPosition);
            this.isLocked = false;
        }
    }

    public checkSliderPosition() {
        const isLocked = this.getLockState;
        if (document.getElementsByClassName('fas_article_tile_grid_root')[0]?.getBoundingClientRect().top < -100) {
            if (isLocked) this.unlock();

            let header : Element = document.getElementById('ats_scope-fas-bar');
            const parent = header.closest('.fas_header');
            if(parent){
                header = parent;
            }
            if (!header) {
                header = document.getElementsByClassName('fas_scope-fas')[0];
            } 

            window.scrollTo({
                behavior: 'smooth',
                top: header.getBoundingClientRect().top
                    - document.body.getBoundingClientRect().top - 50
            })
            if (isLocked) {
                this.timer = null;
                addEventListener('scroll', this.scrollHandler)
            }
        }
    }

    public get getLockState() {
        return this.isLocked;
    }

    private scrollHandler = () => {
        if (this.timer !== null) {
            clearTimeout(this.timer)
        }
        this.timer = setTimeout(() => {
            const mspHidden = document.getElementsByClassName('fas_msp_hidden').length > 0;
            if (!mspHidden) {
                this.lock();
            }
            removeEventListener('scroll', this.scrollHandler)
        }, 500);
    }
}
