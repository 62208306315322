import * as React from 'react';
import * as helper from '../../Common/html-helper';
import classNames from 'classnames';
import styles from './tooltipComponent.scss';
import { ITooltipComponent, ITooltipComponentState } from './tooltipComponent.d';

export default class TooltipComponent extends React.Component<ITooltipComponent, ITooltipComponentState> {

    constructor(props: ITooltipComponent) {
        super(props);
        this.state = {};

        this.closeButtonClicked = this.closeButtonClicked.bind(this);
    }

    public render() {
        return (
            <div className={classNames(styles.tooltip_overlay, this.props.showTooltip ? styles.show : '')}>
                <div className={classNames(styles.modal_box)}>
                    <div className={classNames(styles.close_button)}
                        data-testid='close_button'
                        onClick={this.closeButtonClicked}></div>
                    {!this.props.imageName ? null : <div className={classNames(styles.tooltip_image,
                        this.props.imageName)} />}
                    <div className={styles.tooltip_text}>{this.toolTipText()}</div></div>
            </div>
        );
    }

    private closeButtonClicked(event: React.MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.props.updateTooltip(false, this.props.text, this.props.imageName);
    }

    private toolTipText(): string {
        return this.props.text ? helper.decodeHTML(this.props.text) : '';
    }
}
