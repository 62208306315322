import React from 'react';

const Plus = () => (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12'>
      <path
        fill='currentColor'
        d='M12 5L7 5 7 0 5 0 5 5 0 5 0 7 5 7 5 12 7 12 7 7 12 7 12 5z'
      ></path>
    </svg>
);

export default Plus;
