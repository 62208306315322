import React, { Component } from 'react';
import ArrowDownIcn from '../../../Assets/svg/arrow_down';
import ArticleTileGrid from '../../articleTileGrid/article-tile-grid';
import { 
    ArticleListsToSdvListMapper, 
    ITeaserSuggestionsState,
    ITeaserSuggestionsProps
} from '../searchresultpage.d';
import { PageViewLogDataId, SearchTelemetryAjaxCaller, SearchTelemetryData } from '../SearchTelemetryAjaxCaller';
import * as helper from '../../../Common/html-helper';
import styles from './teaser-suggestions.scss';
import { ViewType } from '../../../Common/enums';
import NewsLetterPromotion from '../../newsLetterPromotion/newsLetterPromotion';

export default class TeaserSuggestions extends Component<ITeaserSuggestionsProps, ITeaserSuggestionsState> {

    constructor(props) {
        super(props);
        this.state = {
            salesDesignationViewList: ArticleListsToSdvListMapper(this.props.articleLists),
        };
    }

    public render() {
        const renderNewsLetter = !(this.props.kiosk || this.props.isMobile);
        return (
            <div className={styles.teaser_suggestion_container}>
                {this.state.salesDesignationViewList.map((list, index) => {
                    const stm = this.props.articleLists[index].telemetryDataViewModel 
                        ? this.props.articleLists[index].telemetryDataViewModel.stm 
                        : null;
                    return (
                        <div className={styles.teaser_suggestion} key={index}>
                            <div className={styles.teaser_suggestion_divider}>
                                <span className={styles.search_term}>
                                    {this.props.articleLists[index].searchString}:
                                </span>
                                <span className={styles.result_count}>
                                    {this.props.articleLists[index].totalResultCount + ' '}
                                    {this.props.articleLists[index].totalResultCount === 1
                                        ? helper.decodeHTML(this.props.l10n.result)
                                        : helper.decodeHTML(this.props.l10n.results)}
                                </span>
                                <hr className={styles.border}/>
                            </div>
                            {(index===0 && renderNewsLetter) && <NewsLetterPromotion isVisible={true} />}
                            <ArticleTileGrid
                                key={index}
                                currencyInformation={this.props.currencyInformation}
                                viewType={ViewType.TeaserSuggestion}
                                isMobile={this.props.isMobile}
                                culture={this.props.culture}
                                portal={this.props.portal}
                                isGlobal={false}
                                isFilterDropDownOpen={false}
                                articles={list}
                                category={null}
                                l10n={this.props.l10n}
                                kiosk={this.props.kiosk}
                                gridNumber={index}
                                globalState={this.props.globalState}
                                articleTilesAjaxCaller={this.props.articleTilesAjaxCaller}
                                addItemOriginToPdpLink={true}
                                singleArticleView={false}
                            />

                            {this.props.articleLists[index].alternativeTarget != null &&
                                <div className={styles.results_button_wrapper}>
                                    <a className={styles.results_button} 
                                        href={this.props.articleLists[index].alternativeTarget}
                                        data-stm={stm}
                                        onClick={()=>this.handleTelemetry(stm)}
                                    >
                                        <span>{helper.decodeHTML(this.props.l10n.seeAllResults)}</span>
                                        <ArrowDownIcn />
                                    </a>
                                </div>
                            }
                        </div>
                      );
                  })}
            </div>
        );
    }

    private handleTelemetry(stm: string): void {
        if (!stm) return;
        const data = new SearchTelemetryData(
            stm,
            PageViewLogDataId.getInstance().get(), 
            this.props.portal,
            this.props.culture,
        );
        SearchTelemetryAjaxCaller.postData(data);
    }
}
