import React, { Component } from 'react';
import styles from './bookmark.scss';
import { ISdvColor } from '../article-tile.d';
import { ViewType } from '../../../../Common/enums';

export default class CompareBookmark extends Component<{ 
    id: string, variant: ISdvColor,
    viewType: ViewType, gridNumber: number,
    categoryName: string, bookmarkMlt: string}, {}> {

    constructor(props) {
        super(props);
    }

    componentDidMount(): void {
        if (typeof window !== 'undefined' && window.shell) {
            // publish msg to bookmark-add-button
            window.shell.publishTo('ESCID.ESPP.Bookmark.CreateClientSideAddBtn', {
                containerId: this.getId(false),
                isMobile: false,
                isRedesignOrderModal: true,
                localization: {bookmark: this.props.bookmarkMlt}
            });
        }
    }

    public render() {
        return (
            <div id={this.getId(true)} 
            data-testid='bookmark_button'
            data-color={this.props.variant.color.name}
            data-categoryname={this.props.categoryName}
            data-savkey={this.props.variant.salesArticleVariantKey}
            className={styles.bookmark_button}>
                    <div id={this.getId(false)} data-containerid={this.getId(false)} />
            </div>
        );
    }

    private getId(isWrapper: boolean): string {
        let id = isWrapper ? `ats_${this.props.id}` : this.props.id;
        if (this.props.viewType === ViewType.TeaserSuggestion) id += `_${this.props.gridNumber}`;
        return id;
    }
}
