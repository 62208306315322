import { IFilteringResult, IAvailableColorsAndSizes } from './article-tile/article-tile.d';
import { IKioskInfo } from '../categorypage/categorypage.d';
import { ISelectedFilter } from '../globalState/selectedFilters.d';
import { ArticleListController } from '../globalState/ArticleListController';
import { GlobalSpinnerState } from '../globalState/globalState';
import { ViewType } from '../../Common/enums';

export class ArticleTilesAjaxCaller {
    constructor(private globalSpinner: GlobalSpinnerState, private magicLink: string) { }

    public loadMoreArticles(pageNo: number, kiosk: IKioskInfo, 
        viewType: ViewType, useSpinner: boolean): Promise<IFilteringResult> {
        const endpoint = viewType === ViewType.Search ? 'load-more-articles-search' : 'load-more-articles';
        const apiUrl = this.buildApiUrl(this.magicLink, endpoint);

        const requestUrl = new URL(apiUrl);
        requestUrl.searchParams.set('pageNumber', pageNo.toString());
        requestUrl.searchParams.set('forPage', window.location.href);
        if (kiosk) {
            requestUrl.searchParams.set('retailStoreNo', kiosk.retailStoreNo.toString());
            if (kiosk.retailStoreFilterMode)
                requestUrl.searchParams.set('retailStoreFilterMode', kiosk.retailStoreFilterMode);
        }
        if(useSpinner)
            this.globalSpinner.startSpinner();

        return fetch(requestUrl.toString(),
            {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            },
        )
            .then((response) => {
                if(useSpinner)
                    this.globalSpinner.stopSpinner();
                if (response.ok)
                    return response.json();
                else
                    throw new Error('response.status = '+response.status);
            })
            .catch((reason) => {
                this.globalSpinner.stopSpinner();
                // eslint-disable-next-line no-console
                console.log(reason);
            });
    }

    public getAvailableSizes(navKey: string, masterArticleNo: number): Promise<IAvailableColorsAndSizes> {
        const endpoint = 'getAvailableSizes';
        const apiUrl = this.buildApiUrl(this.magicLink, endpoint);

        const requestUrl = new URL(apiUrl);
        requestUrl.searchParams.set('navKey', navKey);
        requestUrl.searchParams.set('masterArticleNo', masterArticleNo.toString());

        return fetch(requestUrl,
            {
                credentials: 'include',
                method: 'GET',
                headers: {
                    'Accept': 'application/json'
                }
            },
        ).then((response) => {
            if (response.ok)
                return response.json();
            else
                throw new Error('response.status = ' + response.status);
        })
            .catch((reason) => {
                // eslint-disable-next-line no-console
                console.log(reason);
            });
    }

    private buildApiUrl(magicLink: string, endpoint: string) {
        const apiUrl = magicLink
            + 'api/articletileservice/'
            + endpoint;
        return apiUrl;
    }

    public loadArticlesAfterFilterChange(
        kiosk: IKioskInfo, lastAddedFilter: ISelectedFilter, lastAddedFilterSource: string,
        viewType: ViewType ): Promise<IFilteringResult> {
        const endpoint = viewType === ViewType.Search ? 
            'load-articles-for-changed-filters-search' : 
            'load-articles-for-changed-filters';
        const apiUrl = this.buildApiUrl(this.magicLink, endpoint);

        const requestUrl = new URL(apiUrl);
        if (kiosk) {
            requestUrl.searchParams.set('retailStoreNo', kiosk.retailStoreNo.toString());
            if (kiosk.retailStoreFilterMode)
                requestUrl.searchParams.set('retailStoreFilterMode', kiosk.retailStoreFilterMode);
        }
        requestUrl.searchParams.set('forPage', window.location.href);
        const requestUrlStr = ArticleListController.AddLastAddedForTracing(
            requestUrl.toString(),
            lastAddedFilter,
            lastAddedFilterSource);
        this.globalSpinner.startSpinner();
        return fetch(requestUrlStr,
            {
                credentials: 'include',
                method: 'GET',
            },
            )
            .then((response) => {
                this.globalSpinner.stopSpinner();
                return response.json();
            })
            .catch((reason) => {
                this.globalSpinner.stopSpinner();
                // eslint-disable-next-line no-console
                console.log(reason);
            });
    }
}
