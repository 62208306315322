import React from 'react';
/* tslint:disable */
const IconDamen = () => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox="0 0 22 35">
        <g
            transform="translate(-11.809523,-0.33333337)">
            <g transform="translate(11.809523,0.33333337)">
                <g>
                    <path
                        d="M 11,3 A 8,8 0 1 1 3,11 8,8 0 0 1 11,3 M 11,0 A 11,11 0 1 0 22,11 11,11 0 0 0 11,0 Z" />
                    <rect
                        x="5"
                        y="27"
                        width="12"
                        height="3" />
                    <rect
                        x="9.5"
                        y="21"
                        width="3"
                        height="13.5" />
                </g>
            </g>
        </g>
    </svg>
);

export default IconDamen;
