import * as React from 'react';
import styles from './availableSizesBtn.scss';
import ArrowDownIcn from '../../../../Assets/svg/arrow_down';
import * as helper from '../../../../Common/html-helper';

export default class AvailableSizesBtn extends React.Component<{
    toggleAvailableSizes(): void;
    btnText: string;
}, {}> {
    private elementRef = React.createRef<HTMLDivElement>();

    constructor(props) {
        super(props);
    }

    public render() {
        return (
            <div ref={this.elementRef}
                className={styles.available_sizes_btn}
                data-testid='available_sizes_btn'
                onClick={this.props.toggleAvailableSizes}
                onTouchEnd={this.props.toggleAvailableSizes}
            >
                {helper.decodeHTML(this.props.btnText)}
                <ArrowDownIcn />
            </div>
        );
    }
}
